import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, Subscription, debounceTime, timer } from 'rxjs';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { Environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-developer-manage-subscriptions',
  templateUrl: './developer-manage-subscriptions.component.html',
  styleUrls: ['./developer-manage-subscriptions.component.scss']
})
export class DeveloperManageSubscriptionsComponent implements OnInit {
  searchAppvalue:string='';
  isSearchBusinessFilterEnabled:boolean = false;
  businessFilter: FormControl = new FormControl('');
  filterForm: FormGroup = new FormGroup({});
  itemsPerPage = 10;
  pageNo = 1;
  totalRecordCount =1;
  loaderEnabled:boolean = true;
  dataKeys : string[]=[];
  tableInfo:string="subscriptions";
  showDownloadEmpty:boolean=false;
  failureMsg:string='';
  selectedBusinessValue: string[] = []; // for maintaining state of business Area filters by labels
  @ViewChildren('contentSection') contentSections!: ElementRef[];
  @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
  searchAppSubscription!: Subscription;
  searchAppValueChanged:Subject<string> = new Subject<string>();
  gridArray: any[]=[]; // type of gridArray is any as we need to pass that to generic table component
  businessAreaList =[
    {
  'id': '1',
  'label': 'CT'
  },
  {
  'id': '2',
  'label': 'CO'
  },
  {
  'id': '3',
  'label': 'IT'
  },
  {
  'id': '4',
  'label': 'PT'
  },
  {
  'id': '5',
  'label': 'VT'
  }
  ];
  productTableHeaderList: string [] =[
    "Application Name",
    "Application Number",
    "Application Owner",
    "Business Area",
    "Unit Code",
    "Cost Center",
    "Actions"
  ];
  applicationDetailHeaderList: string[]=[
    "Active Subscriptions",
    "Pending Approval Subscriptions",
    "Rejected Subscriptions"
  ]
  sortingHeaderList=[{head:'Application Name',key:'appName'},{head:'Application Number',key:'appNumber'},
  {head:'Application Owner',key:'appOwner'},{head:'Business Area',key:'businessAreaCode'}
  ,{head:'Unit Code',key:'unitCode'},{head:'Cost Center',key:'costCenter'}
]
constructor(private hipService:Hip2ManagementService){
}
  ngOnInit(): void {
    this.filterForm = new FormGroup({
      filter: this.businessFilter
    });
  this.getApplications();
  this.controlDataChange();
  }
  controlDataChange(){
    //fetching the selected business value and calling the API to get the data based on selected business value
    this.businessFilter.valueChanges.subscribe((x) => {
      let businessValue = x.map((obj:any)=>{return obj.label});
     this.selectedBusinessValue = businessValue;
     this.pageNo=1;
     this.getApplications();
    });    

  }
  getApplications(){
    this.gridArray=[];
    let businessAreaCode = '';
    //get the selected business code
    this.selectedBusinessValue.forEach(element => {
      businessAreaCode=businessAreaCode.length==0?element:(businessAreaCode+','+element)
    });
    let filter = (this.searchAppvalue.trimStart()).trimEnd();
    // we are currently doing client side table sorting
    let sortDirection = 'asc';
    let pageIndex =  this.pageNo;
    let pageSize = this.itemsPerPage;
    let apiVersion = '1.0';
    this.loaderEnabled=true;
    this.hipService.getApplicationsByUser(businessAreaCode, filter, sortDirection,
      pageIndex, pageSize, apiVersion).subscribe(response=>{
        this.totalRecordCount=response.totalCount;
      response.appSubcription.forEach((data:any,index:number)=>{
        let application={"uniqueId":index,
        appName: data.application.applicationName,
        appNumber:data.application.applicationId,
        appOwner:data.application.applicationOwner,
        businessAreaCode:data.application.businessArea,
        unitCode:data.application.unitCode,
        costCenter:data.application.costCenter,
        "Action": 
      {  
        "info": "/developer/manage-subscriptions/"+data.application.applicationId,
        "isQueryParamsReqForInfoLink":true,
        "queryparamsforInfoLink": {'appName' : data.application.applicationName},
        "download": '/developer/manage-subscriptions',
        "downloadParams":{"cmdbId":data.application.applicationId, "applicationName":data.application.applicationName, "businessAreaCode": data.application.businessArea, "appNumber":data.application.applicationId}
    },
    "detail":{
      "headings":this.applicationDetailHeaderList,
      "tableData":[{
        "approvedSubscriptions": data.activeSubscriptions,
        "pendingSubscriptions":data.pendingSubscriptions,
        "rejectedSubscriptions":data.rejectedSubscriptions
    } ]
    }};
    this.gridArray.push(application);
      });
      if(this.gridArray.length>0){
        this.dataKeys = Object.keys(this.gridArray[0]);
        this.dataKeys.splice(this.dataKeys.indexOf("detail"), 1);
      }
      this.loaderEnabled=false;
      this.setContentSectionHeight();
    })
  }
  appSearch(appValue:string){
    this.searchAppValueChanged.next(appValue);
  }
  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    this.searchAppSubscription = this.searchAppValueChanged.pipe(debounceTime(1500)).subscribe(value=>{
      this.searchAppvalue=value;
      if(this.searchAppvalue.length>2 || this.searchAppvalue.length==0){
        this.pageNo=1;
        this.getApplications();    
        this.setContentSectionHeight();

      }     
    })
    this.setContentSectionHeight();
  }
  public renderPageValue(value: number) {
    this.itemsPerPage = value;
    this.pageNo = 1;
    this.getApplications();
   }
   onTablePage(event: any){
    this.pageNo = event;
    this.getApplications();
  }

  setContentSectionHeight() {
    const windowHeight = window.innerHeight;
    let bodyWrapperHeight: any;
    if(this.bodyWrapper!=undefined){
      this.bodyWrapper.forEach(section =>{
        bodyWrapperHeight = windowHeight - section.nativeElement.getBoundingClientRect().top;
        // console.log('bodyWrapperHeight is', bodyWrapperHeight, windowHeight);
      });
      
    }
   if(this.contentSections!=undefined){
    this.contentSections.forEach(section => {
      const offsetTop = section.nativeElement.getBoundingClientRect().top;      
      const sectionHeight = windowHeight - Math.floor(offsetTop) - (window.innerWidth * 0.0052 * 4.8); //(2.5 * 48);
      // console.log('bodyWrapperHeight is', bodyWrapperHeight," section offset is", offsetTop," and section Height is ", sectionHeight," footer height ", window.innerWidth * 0.0052 * 4.8);
      
      section.nativeElement.style.minHeight = `${Math.floor(sectionHeight)}px`;
    });
   }
  }

  onDownloadSubscription(data:any){
     let cmdbId=data.downloadParams.cmdbId??"";
     let appName:string=data.downloadParams.applicationName??"";
     let formattedAppName = appName.toLowerCase().replaceAll(' ','-');
     let appNumber:string=data.downloadParams.appNumber??"";
     let businessAreaCode:string=data.downloadParams.businessAreaCode??"";
     let envValue = Environment.environment;
     this.hipService.getDeveloperManageSubscriptionsToBeExported(cmdbId).subscribe({
      next:(subscriptionList:any[])=>{
        if(subscriptionList.length==0){
          this.showDownloadEmpty=true;
          this.failureMsg='No subscriptions found for '+appName+' application';
          timer(5000).subscribe(x => this.showDownloadEmpty = false);                   
        }else{
          let currentDate= new Date();
          let formattedDate=new DatePipe('en-US').transform(currentDate,"YYYYMMdd-HHmmss");
          const fileName = businessAreaCode.toLowerCase()+'-'+formattedAppName+'-'+appNumber.toLowerCase()+'_subscriptions_'+envValue+'_'+formattedDate+'.xlsx';
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(subscriptionList.map( subscription => ({
            'Product Name'        :  subscription.productName,
            'Environment'         :  envValue, 
            'Subscription Name'   :  subscription.subscriptionName,
            'Subscriber Name'     :  subscription.subscriberName,
            'Application Number'  :  subscription.applicationNumber,
            'Application Name'    :  subscription.applicationName,
            'Business Area'       :  subscription.businessArea,
            'Application Owner'   :  subscription.applicationOwner,
            'State'               :  (subscription.state).toUpperCase()=="SUBMITTED"?"Pending Approval":((subscription.state.charAt(0)).toUpperCase())+(subscription.state).slice(1),
            'Date Requested'      :  subscription.dateRequested,
            'Date Activated'      :  subscription.dateActivated,
            'Usage Notes'         :  subscription.usageNotes,
            'Remarks'             :  subscription.remarks
          })));
          const workbook: XLSX.WorkBook = { Sheets: {'Product Subscriptions':worksheet}, SheetNames: ['Product Subscriptions']};
          const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveExcel(excelBuffer,fileName);
        }
      },
      error: (error:any) => {
        console.error('Error fetching product details:', error);
      }
     })
  }

  saveExcel(buffer:any, fileName: string){
    const blob: Blob = new Blob([buffer],{type: 'application/octet-stream'});
    const url:string = window.URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a');
    document.body.appendChild(a);
    a.href=url;
    a.download=fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  clearSearch(){
    if(this.searchAppvalue.length<3){
      this.searchAppvalue="";
    }else{
      this.searchAppvalue="";
      this.searchAppValueChanged.next("");
    }
  }
}
