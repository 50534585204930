<app-bread-crumb [links]="[
{
  'href': '/publisher/manage-products',
  'title': 'Manage Products'
},
{
  'href': '/publisher/manage-products/view-product/'+this.breadCrumbProductId,
  'title': ' '+this.breadCrumbProduct
},
{
  'href': '/publisher/manage-products/edit-product/'+this.breadCrumbProductId, 
  'title': ' Edit Product'
}
]"></app-bread-crumb>
  <section class="content-section">
    <form class="form-section" [formGroup]="EditProductForm" (ngSubmit)="onSubmit()" [class.loading]="loaderActive" [class.disableClick]="successFull">
      <div class="container form-head">
          <h2 class="title">Edit Product</h2>
          <p>Please change the information below to update this product.</p>

      </div>
      <div class="container">
        <div class="row">
          <app-text-field class="col-sm" [props]="{
            'heading': 'Product Name',
            'placeholder': 'Enter',
            'required': true,
            'disable': false,
            'info': 'Enter the name of the product',
            'errMsg': 'Please enter a valid product name between 3 to 59 characters'
          }"                    
          [control]="getControl('Product')"
          (keydown.enter)="preventSubmit($event)"
          [checkError]="checkError"
          ></app-text-field>
          <app-text-field
            class="col-sm"
            [props]="{
              heading: 'Product Id',
              placeholder: '',
              required: false,
              disable: true,
              info: '',
              errMsg: ''
            }"                                  
            [control]="getControl('ProductId')"
            (keydown.enter)="preventSubmit($event)"
            [checkError]="checkError"
          ></app-text-field>
        </div>
        <div class="row">            
          <app-text-field
            class="col-sm"
            [props]="{
              heading: 'Application Name / Number',
              placeholder: '',
              required: false,
              disable: true,
              info: '',
              errMsg: ''
            }"                                  
            [control]="getControl('AppNameAndNumber')"
            (keydown.enter)="preventSubmit($event)"
            [checkError]="checkError"
          ></app-text-field>
          <app-text-field
            class="col-sm"
            [props]="{
              heading: 'Application Owner',
              placeholder: '',
              required: false,
              disable: true,
              info: '',
              errMsg: ''
            }"                         
            [control]="getControl('AppOwner')"
            (keydown.enter)="preventSubmit($event)"
            [checkError]="checkError"
          ></app-text-field>
        </div>
        <div class="row">          
          <app-text-area
          class="col-sm"
          [props]="{
            heading: 'Description',
            placeholder: 'Enter',
            required: true,
            disable: false,
            info: 'Enter a detailed description about the product ',
            'errMsg': 'Please enter a product description'
          }"
          (keydown.enter)="preventSubmit($event)"
          [control]="getControl('Description')"
          [checkError]="checkError">
          </app-text-area>        
          
          <app-multi-select-dropdown *ngIf="showAPIs"
            class="col-sm-12"
            [props]="{
              heading: 'APIs',
              placeholder: 'Select APIs',
              inputPlaceholder:'Enter to search on APIs',
              required: true,
              disable: false,
              info: 'Select one or more APIs that belong to the product. Only APIs that belong to the selected application from CMDB can be added to the product.  ',
              'errMsg': 'Please select at least one API'
            }"
          (search)="apiSearch($event)"
          [dataSets]="apisDataSet"            
          [control]="getControl('APIs')"
          (keydown.enter)="preventSubmit($event)"
            style="position: relative"
            [checkError]="checkError"
            [defaultDataSet]="defaultApisDataSet"
          ></app-multi-select-dropdown>
        </div>
        <div class="row">           
          <app-text-field
          class="col-sm"
          [props]="{
            heading: 'Product App Registration Name',
            placeholder: '',
            required: false,
            disable: true,
            info: '',
            errMsg: ''
          }"                                  
          [control]="getControl('ProdAppName')"
          (keydown.enter)="preventSubmit($event)"
          [checkError]="checkError"
        ></app-text-field>
          <div class="col-sm-12 radio-wrapper">           
            <app-radio-buttons [props]="{
                'heading':'Published',
                'required': 0,
                'id': 'publish',
                info: 'Should the product be published or not? Only published products are visible for developers to subscribe to. ',
                'radio':[{checked: false, label: 'Yes'}, {checked: false, label: 'No'}]
              }" [control]="getControl('RadioWrapper.Published')"></app-radio-buttons>
              <app-radio-buttons [props]="{
                'heading':'Require&nbsp;Subscription',
                'required': 0,
                'id': 'subs',
                disable: true,
                'radio':[{checked: false, label: 'Yes'}, {checked: false, label: 'No'}]  
              }" [control]="getControl('RadioWrapper.RequiredSubsc')"></app-radio-buttons>
              <app-radio-buttons [props]="{'heading':'Require&nbsp;Approval',
                'required': 0,
                'id': 'appro',
                disable: isReqApprovalAlwaysMandate,
                info: 'Should new product subscriptions be approved or not? We recommend to enable approval to keep track of who is using the product and APIs. ',
                'radio':[{checked: false, label: 'Yes'}, {checked: false, label: 'No'}]
              }" [control]="getControl('RadioWrapper.RequiredApproval')"
              (EnableEvent)="enableApproval($event)"></app-radio-buttons>
            
          </div>
        </div>
        <div class="row">          
          <app-multi-select-dropdown *ngIf="showApprovers"
            class="col-sm-12"
            [props]="{
              heading: 'Approvers',
              placeholder: 'Search in Entra ID',
              required: false,
              disable: false,
              info: 'Select one or more approvers to review new product subscriptions',
              'errMsg': 'Please select at least one approver'
            }"
           (search)="userSearch($event)"
           [dataSets]="approversDataSet"          
            style="position: relative"
            [control]="getControl('Approvers')"
            (keydown.enter)="preventSubmit($event)"
            [checkError]="checkError"
            [disable]="disableApprover"
            [requiredField]="approverRequired"
            [isCustomLabelForOptionsRequired] = "isCustomOptionForApproverRequied"
          ></app-multi-select-dropdown>
        </div>
        <div class="row">          
          <div class="col-sm buttons-align-end button-wrapper">
            <div class="feedbackMsg" [class.feedbackActive]="this.feedbackActive">
              <ng-container *ngIf="successFull" >
                <span class="successColor">{{successMsg}}</span>
                <img src="./assets/successIcon.svg"/>
              </ng-container>
              <ng-container *ngIf="failure" >
                <span class="failureColor">{{failureMsg}}</span>
                <img class="error" src="../../../../assets/Health-monitor/Unhealthy.svg"/>
              </ng-container>
              
            </div>
            <app-buttons
              [props]="{
                icon: '',
                content: 'Cancel',
                href: '#',
                classType: 'tertiary',
                type:'button'
              }" (click)="onClick('/publisher/manage-products/view-product/'+this.breadCrumbProductId)"
            ></app-buttons>
            <app-buttons
              [props]="{
                icon: '',
                content: 'Save',
                href: '#',
                classType: 'primary',
                type:'submit'
              }" 
              *ngIf="editAccessEnabled"             
            ></app-buttons>
          </div>
        </div>
      </div>
      <app-general-loader *ngIf="loaderActive"></app-general-loader>
    </form>
    <div class="info-section container">
      <app-information-section [information]="pageInfo" [detailsInfo]="informationData">
     </app-information-section>
    </div>
  </section>

  <app-alert *ngIf="showSuccess" [success]="true">
    <div class="success_Icon"><img src="./assets/successIcon.svg"/><p>{{successMsg}}</p></div>   
  </app-alert>
  <app-alert *ngIf="showFailure" [failure]="true">
    <div class="unSuccess_Icon"><img src="../../../../assets/Health-monitor/Unhealthy.svg"/><p>{{failureMsg}}</p></div>   
  </app-alert>

