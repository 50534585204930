import { FormGroup, FormControl,  } from '@angular/forms';
import { Component, ViewChildren, ElementRef, HostListener } from '@angular/core';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { Subject, Subscription, debounceTime, timer } from 'rxjs';
import { ManageProductList } from '../../products/models/manage-product-list';
import { DateFormatPipe } from 'src/app/pipes/date-format-pipe.pipe';
import { ProductSubscriptionDetail } from '../../products/models/product-subscription-detail';
import * as XLSX from 'xlsx';
import { Environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-manage-subscriptions',
  templateUrl: './manage-subscriptions.component.html',
  styleUrls: ['./manage-subscriptions.component.scss'],
  providers: [DateFormatPipe]
})
export class ManageSubscriptionsComponent {
  loaderEnabled: boolean = true;
  searchProductvalue: string = ''; 
  gridArray: any[]=[]; // type of gridArray is any as we need to pass that to generic table component
  selectedBusinessvalue: string[] = []; // for maintaining state of business Area filters
  dataKeys : string[]=[];
  pageNo: number = 1;
  itemsPerPage : number = 10;
  filterForm: FormGroup = new FormGroup({});
  businessFilter: FormControl = new FormControl('');
  totalRecordCount:number =0;
  isSearchBusinessFilterEnabled:boolean = false;
  tableInfo:string="products"
  enableColonDateFormat:boolean=false;
  showDownloadEmpty:boolean=false;
  failureMsg:string=''
  productTableHeaderList: string [] =[
   "Product Name",
   "Application Number",
   "Business Area",
   "Unit Code",
   "Application Owner",
   "Cost Center",
   "State",
  "Date Published",
  "Require Subscription",
  "Actions"
 ];
 
 applicationDetailHeaderList: string[]=[
   "Active Subscriptions",
   "Pending Approval Subscriptions",
   "Rejected Subscriptions"
 ]
 
 businessAreaList =[
   {
 'id': '1',
 'label': 'CT'
 },
 {
 'id': '2',
 'label': 'CO'
 },
 {
 'id': '3',
 'label': 'IT'
 },
 {
 'id': '4',
 'label': 'PT'
 },
 {
 'id': '5',
 'label': 'VT'
 }
 ];
 
 sortingHeaderList=[{head: 'Product Name', key: 'productName'}
,{head: 'Application Number' , key: 'applicationNumber'},{head:'Business Area', key: 'businessArea'},{head:'Unit Code', key: 'unitCode'}
,{head: 'Application Owner', key: 'applicationOwner'}, {head: 'Cost Center', key: 'costCenter'}, {head: 'State', key: 'state'},{head:'Date Published' ,key: 'datePublished'}
];
 searchProductSubscription!: Subscription;
 searchProductValueChanged:Subject<string> = new Subject<string>();
  @ViewChildren('contentSection') contentSections!: ElementRef[];
  @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
 sectionHeight = 0;
  dateFormatPipe: any;
  environmentValue:string='';
 // listen for window resize events
 @HostListener('window:resize')
 onResize() {
  this.setContentSectionHeight();
 }
 setContentSectionHeight() {
  const windowHeight = window.innerHeight;
 if(this.contentSections!=undefined){
   this.contentSections.forEach(section => {
     const offsetTop = section.nativeElement.getBoundingClientRect().top;      
     const sectionHeight = windowHeight - Math.floor(offsetTop) - (window.innerWidth * 0.0052 * 4.8);
     //0.0052 is the constant value which will be used as dynamic value changing for font-size and 4.8 is the height of footer in rem  
     section.nativeElement.style.minHeight = `${Math.floor(sectionHeight)}px`;
   });
   }
 }
 
  constructor(private hipManagementService:Hip2ManagementService, private dataPipe: DateFormatPipe) { }
 
  public renderPageValue(value: number) {
    this.itemsPerPage = value;
    this.pageNo = 1;
    this.loadProductList()
   }
 
   ngOnInit(): void {
    this.loadProductList();
    this.filterForm = new FormGroup({
      filter: this.businessFilter
    });
    switch (Environment.environment) {
      case 'internaldev':
                this.environmentValue='INTERNAL DEV';
        break;
      case 'internaltst':
        this.environmentValue='INTERNAL TST'
        break;
      case 'dev':
        this.environmentValue='DEVELOPMENT'
        break;
      case 'tst':
        this.environmentValue='TEST'
        break;
        case 'prd':
        this.environmentValue='PRODUCTION'
        break;
    }
    this.controlDataChange()   
  }
  ngAfterContentInit(): void {
    this.searchProductSubscription = this.searchProductValueChanged.pipe(debounceTime(1500)).subscribe(value=>{
     this.searchProductvalue=value;
     if(this.searchProductvalue.length>2 || this.searchProductvalue.length==0){
       this.pageNo=1;
       this.loadProductList();    
       this.setContentSectionHeight();  
         }   
    })
    this.setContentSectionHeight();
  }
  controlDataChange(){
    this.businessFilter.valueChanges.subscribe((businessFilterObject) => {
      let businessValue = businessFilterObject.map((obj:any)=>{return obj.label});     
     this.selectedBusinessvalue = businessValue;
     this.pageNo=1;
     this.loadProductList();    
    });   
  }
 
  onTablePage(event: any){
    this.pageNo = event;
    this.loadProductList()
  }
  loadProductList(){
    let businessAreaCode = '';
    //get the selected business code
    this.selectedBusinessvalue.forEach(element => {
      businessAreaCode=businessAreaCode.length==0?element:(businessAreaCode+','+element)
    });
    let filter = (this.searchProductvalue.trimStart()).trimEnd();
    // we are currently doing client side table sorting
    let sortDirection = 'asc';
    let pageIndex =  this.pageNo;
    let pageSize = this.itemsPerPage;
    let apiVersion = '1.0';
    this.loaderEnabled = true;
    this.hipManagementService.getProductsAndApis(businessAreaCode, filter, sortDirection,
      pageIndex, pageSize, apiVersion).subscribe
      ({
          next: (productsAndApi: ManageProductList) => {
            //resetting the grid array in order to get latest data
            this.gridArray=[]
            productsAndApi.productData.forEach(productDetail => {
                let product = {
                  "uniqueId":productDetail.id,
                  "productName":productDetail.displayName,
                  "applicationNumber":productDetail.cmdbId,
                  "businessArea": productDetail.businessAreaCode,
                  "unitCode": productDetail.unitCode,
                  "applicationOwner": productDetail.appOwner,
                  "costCenter": productDetail.costCenter,
                  "state": productDetail.state=='notPublished'?"Unpublished":((productDetail.state).charAt(0).toUpperCase())+(productDetail.state).slice(1),
                  "datePublished": productDetail.datePublished!=undefined?this.dataPipe.transform(productDetail.datePublished, this.enableColonDateFormat):"  - | -",
                  "requireSubscription":productDetail.requiredSubscription,
                  "Action": 
                    {  
                      "apimProductName":productDetail.apimProductName,
                      "info": "/publisher/manage-subscriptions/"+productDetail.apimProductName,
                      "download": "/publisher/manage-subscriptions"+productDetail.apimProductName, 
                      "isQueryParamsReqForInfoLink":true,
                      "queryparamsforInfoLink": {'productName' : productDetail.displayName}
                  }
                  ,
                  "detail":{
                    "headings":this.applicationDetailHeaderList,
                    "tableData":[]
                 }
                }
                this.gridArray.push(product);
            }); 
           
            if(this.gridArray.length>0){
              this.dataKeys = Object.keys(this.gridArray[0]);
              this.dataKeys.splice(this.dataKeys.indexOf("detail"), 1);
              
            }
            this.totalRecordCount = productsAndApi.totalCount;
            this.loaderEnabled = false;
            this.resetScroll();
            this.setContentSectionHeight();
          },
          error: (errorDetail: string) => {
      this.loaderEnabled = false;
      console.debug('Could not make connection because of error ', errorDetail);
      this.setContentSectionHeight();
          }
          
        });
  }
 
  productSearch(searchValue: string){
   this.searchProductValueChanged.next(searchValue)
  }

  childListData(product: any)
  {  
    let childData: ProductSubscriptionDetail[] =[];
    let apiVersion= '1.0'
    this.hipManagementService.getSubscriptionsStatistics(apiVersion,product.Action.apimProductName).subscribe(
      {      
        next: (subscriptionDetail:ProductSubscriptionDetail) => {                   
        childData.push(subscriptionDetail);
         let gridArrayWithAPI = [...this.gridArray];
         console.log("this.gridArray",this.gridArray)
         gridArrayWithAPI.find(i => i.uniqueId ===product.uniqueId).detail.tableData=childData;
         this.gridArray= [...gridArrayWithAPI]
        },
        error: (err: string) => {    
          console.log("error",err);        
          let emptyResponse = {
            "approvedSubscriptions": 0,
            "pendingSubscriptions":0,
            "rejectedSubscriptions":0,
            "productId":product.Action.apimProductName
        } 
        childData.push(emptyResponse);     
          let gridArrayWithAPI = [...this.gridArray]
          gridArrayWithAPI.find(i => i.uniqueId ===product.uniqueId).detail.tableData=childData;
          this.gridArray= [...gridArrayWithAPI]        } 
      });   
      this.loaderEnabled= false;
  }

  resetScroll(){    
    const BodyElement: HTMLElement | null = document.getElementById('bodyContent');
    if(BodyElement != null ){
      BodyElement.scrollTop = 0;
    }
  }

  downloadProductSubscripitonsDetails(product: any): void {
    let apimProductId:string=product.apimProductName;
    let productName=product.queryparamsforInfoLink.productName??'';
    let envValue= Environment.environment;
    this.hipManagementService.getSubscriptionsToBeExported(apimProductId).subscribe({
      next: (productDetails: any[]) => {
        if(productDetails.length==0){
          this.showDownloadEmpty=true;
          this.failureMsg='No subscriptions found for '+productName+' product';
          timer(5000).subscribe(x => this.showDownloadEmpty = false);                   
        }else{
          let currentDate = new Date();
          let formattedDate=new DatePipe('en-US').transform(currentDate,"YYYYMMdd-HHmmss");
       const fileNameWithEnv = `${apimProductId}_subscriptions_${envValue}_${formattedDate}.xlsx`;
       const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(productDetails.map(subscription =>({
         'Product Name'        :  productName,
         'Environment'         : this.environmentValue, 
         'Subscription Name'   :  subscription.subscriptionName,
         'Subscriber Name'     :  subscription.subscriberName,
         'Application Number'  :  subscription.applicationNumber,
         'Application Name'    :  subscription.applicationName,
         'Business Area'       :  subscription.businessArea,
         'Application Owner'   :  subscription.applicationOwner,
         'State'               :  (subscription.state).toUpperCase()=="SUBMITTED"?"Pending Approval":((subscription.state.charAt(0)).toUpperCase())+(subscription.state).slice(1),
         'Date Requested'      :  subscription.dateRequested,
         'Date Activated'      :  subscription.dateActivated,
         'Usage Notes'         :  subscription.usageNotes,
         'Remarks'             :  subscription.remarks

       })));
       const workbook: XLSX.WorkBook = { Sheets: { 'Product Subscriptions': worksheet }, SheetNames: ['Product Subscriptions'] };
       const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
       this.saveExcelFile(excelBuffer, fileNameWithEnv);
   }},
   error: (error: any) => {
       console.error('Error fetching product details:', error);
   }
});
}


  
 
  saveExcelFile(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const a: HTMLAnchorElement = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
a.download = fileName;
a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  clearSearch(){
    if(this.searchProductvalue.length>2){
      this.searchProductValueChanged.next("");
    }
    this.searchProductvalue="";

  }
 }
 