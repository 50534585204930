import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { StateService } from 'src/app/shared/services/state.service';
import { ViewModel,ApiLists } from '../../models/view-model';
import { ActivatedRoute } from '@angular/router';
import { Router} from'@angular/router';
import { User } from '../models/user';
import {DateFormatPipe} from "../../../pipes/date-format-pipe.pipe"
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';

@Component({
  selector: 'view-product-card',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss'],
  providers:[DateFormatPipe]
})

export class ViewProductComponent implements OnInit{
dataset: ViewModel={productName: '',
  description: '',
  applicationOwner: '',
  businessArea: '',
  published: '',
  requireApproval:'',
  subscriptionRequired:'',
  productID:'',
  registration:'',
  appRegistrationId:'',
  apiData: [],
  approvers: [],
  apitags: []};
detailsInfo:any[]=[];
apiTags:string[] = [];
productInfo:{parentData:{productName:string}}={parentData:{productName:""}}
loaderActive: boolean = true;
breadCrumbProductName:string = "";
breadCrumbProductId:string="";
apimProductId: string ="";
ProductName = new  FormControl('', [Validators.required, Validators.minLength(3)]);
ProductId = new FormControl('');
ViewProductForm : FormGroup = new FormGroup({}); 
appRegistrationId: string ="";
appRegistrationName: string ="";
pageInfo:string = "A Product is a collection of one or more APIs. Approvers can be assigned to keep control over any new Product subscriptions. Once approved, a subscribing application gets access to all APIs inside a Product.\n\n You are viewing a Product that has been created in Azure API Management."
buttonProps:any = [];
approverList: string[] = [];
enableColonDateFormat:boolean = true;
isDraftProduct:boolean = false;
constructor(private hipManagementService: Hip2ManagementService,
  private stateservice: StateService,protected route: ActivatedRoute, private router:Router, private dateFormatPipe: DateFormatPipe, private errorHandleService: ErrorHandleService){
    let passedData:any={}; // Type is any as it will map input data with different structure from many pages i.e ds table , View Product 
    passedData=this.router.getCurrentNavigation()?.extras.state??{productName:""};
    if(passedData.parentData != undefined){
      this.breadCrumbProductName=passedData.parentData.productName;
      sessionStorage.setItem(this.route.snapshot.paramMap.get('productId') ?? "",this.breadCrumbProductName);
    }else{
      this.breadCrumbProductName=sessionStorage.getItem(this.route.snapshot.paramMap.get('productId') ?? "")??"";
    }
  }

ngOnInit(): void {
  this.breadCrumbProductId = this.route.snapshot.paramMap.get('productId') ?? "";
  this.ViewProductForm =new FormGroup({
    ProductName : this.ProductName,
    
  });

  this.getProductByProductId(this.breadCrumbProductId!.toString());// Harded value, should be pass here from Manage product Grid.

}

getProductByProductId(apimProductId: string) {
  this.hipManagementService.getProductByProductIdPublisherSection(apimProductId).subscribe(r => {
    let product = r;
    if(!(product.hasAccess)){
      this.router.navigate(['/unauthorized']);
    }else{    
    this.breadCrumbProductName=product.productName;
    sessionStorage.setItem(this.route.snapshot.paramMap.get('productId') ?? "",this.breadCrumbProductName);
    this.productInfo={parentData:{productName:this.breadCrumbProductName}}
  for (let index = 0; index < product.approvers.length; index++) {
    let email = product.approvers[index];
    this.hipManagementService.getListOfADUsers_Approvers(email).subscribe({
      next:(response)=>{
       response.forEach((userData: User) => {
       this.approverList.push(userData.name);
       });
      }})
  }
  let productAPIs=[];
for (let index = 0; index < (product.apis).length; index++) {
  let element = product.apis[index];
  let api={
    apimApiName: element.apimApiName,
    name:element.name,
    apiOwner:element.apiOwner,
    apiLink:'/publisher/view-api/'+element.apimApiName
  }
  productAPIs.push(api);
}
    this.dataset = {
      productName: product.productName,
      productID: product.apimProductName,
      applicationOwner: product.appOwner,
      businessArea:product.businessAreaCode,
      description: product.description,
      published: product.state=="published"?"Yes":"No",
      subscriptionRequired:product.subscriptionRequired.toString().toLowerCase()=="true"?"Yes":"No",
      registration: this.appRegistrationName,
      appRegistrationId: product.productAppRegistration,
      requireApproval: product.approvalRequired.toString().toLowerCase()=="true"?"Yes":"No",
      approvers: product.approvers.length==0?["none"]:this.approverList,
      apitags: this.apiTags,
      apiData: productAPIs
    }
    this.isDraftProduct=product.isDraftProduct;
    let buttonPropsWithEditOption = [{content:'Manage Subscriptions' , classType:'tertiary', icon:'./assets/buttons/ManageIcon.svg', routePath: '/publisher/manage-subscriptions/'+this.breadCrumbProductId, isParamsReq: true, params:{'productName' : product.productName} }, {content:'Edit', classType:'primary', icon:'./assets/buttons/editIcon.svg', routePath: this.isDraftProduct?'/publisher/manage-products/edit-draft-product/'+this.breadCrumbProductId:'/publisher/manage-products/edit-product/'+this.breadCrumbProductId, state:this.productInfo }]
    let buttonPropsWithoutEditOption = [{content:'Manage Subscriptions' , classType:'tertiary', icon:'./assets/buttons/ManageIcon.svg', routePath: '/publisher/manage-subscriptions/'+this.breadCrumbProductId,isParamsReq: true, params:{'productName' : product.productName} }]
    this.buttonProps=product.hasReadonlyAccess?buttonPropsWithoutEditOption:buttonPropsWithEditOption;
    this.appRegistrationId=product.productAppRegistration;
    this.detailsInfo = product.state=="notPublished"?[
      {'title':'Application Name', 'value':product.applicationName},
      {'title':'Application Number', 'value':product.cmdbId},
      {'title':'Application Owner', 'value':product.appOwner},
      {'title':'Business Area', 'value': product.businessAreaCode },
      {'title':'Unit Code', 'value':product.unitCode },
      {'title':'Cost Center', 'value': product.costCenter },
      {'title':'Product Creator', 'value': product.createdBy },
      {'title':'Date Requested', 'value': this.dateFormatPipe.transform(product.requestedDate, this.enableColonDateFormat)},
      {'title':'Date Unpublished', 'value': product.dateUnPublished!=undefined?this.dateFormatPipe.transform(product.dateUnPublished, this.enableColonDateFormat):"  - | -"}
    ] :[
      {'title':'Application Name', 'value':product.applicationName},
      {'title':'Application Number', 'value':product.cmdbId},
      {'title':'Application Owner', 'value':product.appOwner},
      {'title':'Business Area', 'value': product.businessAreaCode },
      {'title':'Unit Code', 'value':product.unitCode },
      {'title':'Cost Center', 'value': product.costCenter },
      {'title':'Product Creator', 'value': product.createdBy },
      {'title':'Date Requested', 'value': this.dateFormatPipe.transform(product.requestedDate, this.enableColonDateFormat)},
      {'title':'Date Published', 'value': product.datePublished!=undefined?this.dateFormatPipe.transform(product.datePublished, this.enableColonDateFormat):"  - | -"}
    ];  
    

    console.log("Product By Id:" + JSON.stringify(product));
    this.loaderActive = false;  

    if(!this.isDraftProduct){
    this.hipManagementService.getTagsByProductId(apimProductId).subscribe(a => {
      this.dataset.apitags = a;
      console.log("tags for product Id:" + this.apimProductId);
    });
    
    this.hipManagementService.getAdAppRegistrationNameByAppId(this.appRegistrationId).subscribe(a => {
      this.dataset.registration = a[0].clientAppName;
    });
  }
  }
},error=>{
this.errorHandleService.handleError(error);
});
}

onViewApiClick(apiId:string, apiName:string){
sessionStorage.setItem(apiId,apiName);
}
}

