<div #bodyWrapper>
    <app-bread-crumb [links]="[
    {
      'href': '/publisher/manage-apis',
      'title': 'Manage APIs'
    },
    {
      'href':  '/publisher/manage-apis/view-api/'+this.breadCrumbApiId,
      'title': ' '+this.breadCrumbApiName
    },
    {

     'href':  '/publisher/manage-apis/view-api/'+this.breadCrumbApiId,   
     'title': 'API Details'
        
     }
  ]"[buttonProps]="buttonProperties"></app-bread-crumb>
         <app-general-loader *ngIf="loaderActive"></app-general-loader>
  <section class="content-section"  #contentSection>
    <div class="form-section" [class.loading]="loaderActive">
        <div class="container form-head" [class.loading]="loaderActive" style="display: flex; justify-content: space-between; flex-wrap: wrap;">
            <div>
                <h2 class="title">API Details</h2>
                <p>Please find a detailed overview of all API related information below.</p>
            </div>
        </div>
        <div class="container" id="view" [class.loading]="loaderActive" >
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title">API Name</h3>
                    <h3 class="bold title">{{this.dataset.displayName}}</h3>
                </div>
                <div class="col-12 col-sm">
                    <h3 class="title">API Id</h3>
                    <h3 class="title bold">{{this.dataset.appNumber}}</h3>
                </div>
            </div>
            <div class="row" [class.loading]="loaderActive">
                <div class="col-12 col-sm">
                    <h3 class="title">API Version</h3>
                    <h3 class="title bold">{{this.dataset.apiVersion}}</h3>
                </div>
                <div class="col-12 col-sm">
                    <h3 class="title">API Owner</h3>
                    <app-render-control-values [value]="this.dataset.apiOwner"  [type]="'tag'" [isTagLabel]=true></app-render-control-values>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title">API URL Suffix</h3>
                    <p class="title blue-content">{{this.dataset.urlSuffix}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12" [ngClass]="'description-container'">
                    <h3 class="title">Description</h3>
                    <h3 class="title bold">{{this.dataset.apiDescription}}</h3>
                </div>
            </div>
            
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <h3 class="title">Tags</h3>
                    <app-render-control-values [value]="this.tags" [type]="'tag'" [isTagLabel]=true></app-render-control-values>
                </div>
            </div>
        </div>
        <div class="container productTable" [class.loading]="loaderActive">
            <div class="table-wrapper">
                <table>
                        <tr>
                            <th>Product Name</th>
                            <th>Product Owner</th>
                        </tr>             
                    <tbody>
                        <ng-container *ngFor="let item of this.productList;">
                            <tr>
                                <td><a class="blue-content">{{item.productName}}</a></td>
                                <td class="bold-content">{{item.applicationOwner}}</td>                               
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>                
        </div>
    </div>
    <div class="info-section container" >
        <app-information-section [information]="pageInfo" [detailsInfo]="this.detailsInfo"></app-information-section>
      </div>
    </section>
   
</div>