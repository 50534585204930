<div #bodyWrapper>
    <app-bread-crumb [links]="[
    {
        'href': '/developer/all-products',
        'title': 'Products & APIs' 
    },
    {
        'href': '/developer/view-product/'+this.breadCrumbProductId,
        'title': ''+this.breadCrumbProductName
    },
    {
        'title': 'Product Details'
    }
      
  ]" [buttonProps]="[
  {content:'Subscribe', classType:'primary', icon:'./assets/buttons/subscribeIcon.svg',routePath: '/developer/product-subscription/'+this.breadCrumbProductId, isParamsReq: true,params:{productName: this.breadCrumbProductName}}]" ></app-bread-crumb>
  <section class="content-section" >
<app-general-loader *ngIf="loaderActive"></app-general-loader>
<div class="form-section" [class.loading]="loaderActive">
    <div class="container form-head" [class.loading]="loaderActive">
        <h2 class="title">Product Details</h2>
        <p>Please find a preview and detailed overview of all product related information below.</p>

    </div>
    <div class="container cardWrapper" [class.loading]="loaderActive">
        <app-product-card [dataset]="this.dataset" [enableAPIQueryParams]="enableAPIQueryParams" [showSingleCardStyle]=true  [isClickableLabelReq]=true   (tagSearchEvent)="onTagSearch($event)"></app-product-card>
    </div>
    <div class="container" id="view" [class.loading]="loaderActive">
        <div class="row">
            <div class="col-12 col-sm">
                <h3 class="title">Product Name</h3>
                <h3 class="bold title">{{this.dataset.productName}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12">
                <h3 class="title">Description</h3>
                <h3 class="title bold description-overflow">{{this.dataset.description}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-5">
                <h3 class="title">Require Approval</h3>
                <h3 class="title bold">{{this.dataset.requireApproval}}</h3>
            </div>  
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <h3 class="title">Approvers</h3>
                <app-render-control-values [value]="this.dataset.approvers" [type]="'tag'" [isTagLabel]=true></app-render-control-values>
            </div>
        </div>

    </div>
    <div class="container productTable" [class.loading]="loaderActive">
                <div class="table-wrapper">
                    <table>
                            <tr>
                                <th>API Name</th>
                                <th>API Owner</th>
                                <!-- <th>Security</th>
                                <th>Action</th>-->
                            </tr>             
                        <tbody>
                            <ng-container *ngFor="let api of this.dataset.apiData; let i = index">
                                <tr>
                                    <td><a [routerLink]="'/developer/api-definition/'+api.apimApiName" class="blue-content" [queryParams]="{'apiName': api.name, 'productId':this.breadCrumbProductId, 'productName':this.breadCrumbProductName}">{{api.name}}</a></td>
                                    <td class="bold-content">{{api.apiOwner}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>                
    </div>
</div>
<div class="info-section container">
    <app-information-section [information]="pageInfo" [detailsInfo]="this.detailsInfo"></app-information-section>
  </div>

</section>
</div>