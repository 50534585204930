import { FormGroup, FormControl,  } from '@angular/forms';
import { Component, ViewChildren, ElementRef, HostListener } from '@angular/core';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { ApiList } from '../models/api-list';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { DateFormatPipe } from 'src/app/pipes/date-format-pipe.pipe';
import { Environment } from 'src/environments/environment';


@Component({
  selector: 'app-manage-api',
  templateUrl: './manage-api.component.html',
  styleUrls: ['./manage-api.component.scss'],
  providers: [DateFormatPipe]
})
export class ManageApiComponent {
 loaderEnabled: boolean = true;
 searchApivalue: string = ''; 
 gridArray: any[]=[]; // type of gridArray is any as we need to pass that to generic table component
 selectedBusinessvalue: string[] = []; // for maintaining state of business Area filters
 dataKeys : string[]=[];
 pageNo: number = 1;
 itemsPerPage : number = 10;
 filterForm: FormGroup = new FormGroup({});
 businessFilter: FormControl = new FormControl('');
 totalRecordCount:number =0;
 isSearchBusinessFilterEnabled:boolean = false;
 tableInfo:string="APIs"
 enableColonDateFormat:boolean=false;
 azureWorkBookAllowedEnv=["internaltst","prd"];
 isWorkBookEnabled:boolean=false;
 apiTableHeaderList: string [] =[
  "API Name",
  "Version",
  "Business Area",
  "API Owner",
  "Date Registered",
  "Actions"
];

applicationDetailHeaderList: string[]=[
  "Application Number",
  "Application Name",
  "Application Owner"
]

businessAreaList =[
  {
'id': '1',
'label': 'CT'
},
{
'id': '2',
'label': 'CO'
},
{
'id': '3',
'label': 'IT'
},
{
'id': '4',
'label': 'PT'
},
{
'id': '5',
'label': 'VT'
}
];

sortingHeaderList=[{head: 'API Name', key: 'apiName'},{head: 'Version', key: 'version'}, {head: 'Business Area', key: 'businessArea'}
,{head: 'API Owner', key: 'apiOwner'}, {head: 'Date Registered', key: 'dateRegistered'}, {head: 'Activated Date', key: 'activatedDate'}]

searchAPISubscription!: Subscription;
searchAPIValueChanged:Subject<string> = new Subject<string>();
 @ViewChildren('contentSection') contentSections!: ElementRef[];
 @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
sectionHeight = 0;
// listen for window resize events
@HostListener('window:resize')
onResize() {
 this.setContentSectionHeight();
}
setContentSectionHeight() {
 const windowHeight = window.innerHeight;
if(this.contentSections!=undefined){
  this.contentSections.forEach(section => {
    const offsetTop = section.nativeElement.getBoundingClientRect().top;      
    const sectionHeight = windowHeight - Math.floor(offsetTop) - (window.innerWidth * 0.0052 * 4.8);
    //0.0052 is the constant value which will be used as dynamic value changing for font-size and 4.8 is the height of footer in rem  
    section.nativeElement.style.minHeight = `${Math.floor(sectionHeight)}px`;
  });
  }
}

 constructor(private hipManagementService:Hip2ManagementService, private dateFormatPipe: DateFormatPipe) { }

 public renderPageValue(value: number) {
   this.itemsPerPage = value;
   this.pageNo = 1;
   this.loadApiList()
  }

  ngOnInit(): void {
  this.isWorkBookEnabled = this.azureWorkBookAllowedEnv.includes(Environment.environment);
   this.loadApiList();
   this.filterForm = new FormGroup({
     filter: this.businessFilter
   });
   this.controlDataChange()   
 }
 ngAfterContentInit(): void {
   this.searchAPISubscription = this.searchAPIValueChanged.pipe(debounceTime(1500)).subscribe(value=>{
    this.searchApivalue=value;
    if(this.searchApivalue.length>2 || this.searchApivalue.length==0){
      this.pageNo=1;
      this.loadApiList();    
      this.setContentSectionHeight();  
        }   
   })
   this.setContentSectionHeight();
 }
 controlDataChange(){
   this.businessFilter.valueChanges.subscribe((businessFilterObject) => {
     let businessValue = businessFilterObject.map((obj:any)=>{return obj.label});     
    this.selectedBusinessvalue = businessValue;
    this.loadApiList();    
   });   
 }

 onTablePage(event: any){
   this.pageNo = event;
   this.loadApiList()
 }
 loadApiList(){
  let filter= (this.searchApivalue.trimStart()).trimEnd();
  let sortDirection = 'asc';
  let pageIndex = this.pageNo;
  let pageSize = this.itemsPerPage;
  let businessAreaCode = '';
  //get the selected business code
  this.selectedBusinessvalue.forEach(element => {
    businessAreaCode=businessAreaCode.length==0?element:(businessAreaCode+','+element)
  });  this.loaderEnabled=true;
  this.hipManagementService.getApis(filter, sortDirection, pageIndex, pageSize,businessAreaCode).subscribe({
    next:(apiData: ApiList) =>{
      this.gridArray=[];
      apiData.apiDetails.forEach(apiDetail => {
        let apiName=apiDetail.apimApiName;
        let azureWorkBookLink:String="https://portal.azure.com/#view/AppInsightsExtension/WorkbookViewerBlade/ComponentId/azure%20monitor/ConfigurationId/%2Fsubscriptions%2Fea100b2e-e340-4821-b3c5-19a199e931ef%2Fresourcegroups%2Frg-p-app-10001236-hip-prd%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2F0ab9fec4-ff61-4f32-8ae9-9c4652892016/WorkbookTemplateName/API%20Dashboard/NotebookParams~/%7B%22APIID%22%3A%22"+apiName+"%22%7D";
        let applicationInformation ={
        "applicationNumber": apiDetail.cmdbId,
        "applicationName": apiDetail.applicationName,
        "applicationOwner":apiDetail.applicationOwner
        }
        let childTableData=[applicationInformation];
        let api={
          "uniqueId":apiDetail.id,
          "apiName":apiDetail.apiDisplayName,
          "version":apiDetail.version,
          "businessArea":apiDetail.businessAreaCode,
          "apiOwner":apiDetail.apiOwner,
          "dateRegistered":apiDetail.activatedOn==undefined?"":this.dateFormatPipe.transform(apiDetail.activatedOn,this.enableColonDateFormat),
          "Action": 
                  {  
                    "edit": "/publisher/manage-apis/edit-api/"+apiDetail.apimApiName,
                    "info": "/publisher/manage-apis/view-api/"+apiDetail.apimApiName,
                    "delete": "",
                    "azureDashboardLink": this.isWorkBookEnabled?azureWorkBookLink:""
                },
           "detail":{
            "headings":this.applicationDetailHeaderList,
            "tableData":childTableData
           }     
        }
        this.gridArray.push(api);
      });
      if(this.gridArray.length>0){
        this.dataKeys = Object.keys(this.gridArray[0]);
        this.dataKeys.splice(this.dataKeys.indexOf("detail",1))
      }
      this.loaderEnabled=false;
      this.totalRecordCount=apiData.totalCount;
      this.resetScroll();
      this.setContentSectionHeight();
    },
    error: (err: string) => {console.log("Error response " + err);this.loaderEnabled=false;
      this.setContentSectionHeight();
  }
  })
 }

 apiSearch(apiValue: string){
  this.searchAPIValueChanged.next(apiValue)
 }
 resetScroll(){    
  const BodyElement: HTMLElement | null = document.getElementById('bodyContent');
  if(BodyElement != null ){
    BodyElement.scrollTop = 0;
  }
}
clearSearch(){
if(this.searchApivalue.length>2){
  this.searchAPIValueChanged.next("")
}
this.searchApivalue="";
}
}
