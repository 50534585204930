<div #bodyWrapper class="section">
    <app-bread-crumb [links]="[
    {
      'href': '/developer/manage-subscriptions',
      'title': 'Manage Subscriptions',
      'description': 'An overview of all product subscriptions for your application(s) as an application owner.'
    },
    {
    'title': this.breadCrumbAppName
    }  
    ]"></app-bread-crumb>
    <section class="content-section container"  #contentSection>
        <div class="ds_c-listing-table">
            <div class="ds_c-listing-table-filter col-sm-12">
                <div class="navbar col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                  <span class="col-12 col-sm-12 col-md-4" style="position: relative">
                    <input
                      #searchSubscriptionField
                      type="text"
                      class="text-field-responsive form-control"
                      name="searchProductvalue"
                      [(ngModel)]="searchProductvalue"
                      (ngModelChange)="productSearch($event)"
                      placeholder="Search with at least 3 characters"
                      (keydown.enter)="searchSubscriptionField.blur()"
                    />
                    <span class="search-icon-placement" *ngIf="searchProductvalue.length==0"></span>
                    <span *ngIf="searchProductvalue.length > 0" class="removeBtn-search" (click)="clearSearch()" ></span>
                  </span>
                  <span [formGroup]="filterForm" class="col-12 col-sm-12 col-md-7 filter-placement">
                      <app-multi-select-dropdown class="multi-select" [props]="{placeholder: 'Filter on state'}" [control]="stateFilter" [disableUserInteraction]="this.loaderEnabled" [dataSets]="stateList" [isSearchEnabled]="isSearchStateFilterEnabled"></app-multi-select-dropdown>
                  </span>            
                </div>
                <span class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 pageControls">
                  <span class="ds_c-renderCount">
                    <span>{{totalRecordCount === 0? '00':(((this.pageNo-1) * (itemsPerPage) + 1) === 1) ? '01' : ((this.pageNo-1) * (itemsPerPage) + 1)}} - {{((itemsPerPage) * (this.pageNo) > (this.totalRecordCount)) ? this.totalRecordCount : (itemsPerPage) * (this.pageNo)}} of {{this.totalRecordCount}}</span>
                    <app-select-drpdwn [Options]="[10, 20, 30]" [perPageRender]="this.itemsPerPage" (perPageValue)="renderPageValue($event)"></app-select-drpdwn>
                  </span>
                  <pagination-controls class=" ds_c-pagination" (pageChange)="onTablePage($event)"  [maxSize]="5"  (responsive)="true"  previousLabel=""
                  nextLabel=""></pagination-controls>
                </span>
              </div>
              <div style="overflow-x: auto;">
                <app-shimmer-table *ngIf="this.loaderEnabled == true"></app-shimmer-table>
                <app-ds-table *ngIf="this.loaderEnabled == false" [HeadArray]="productTableHeaderList" [GridArray]="gridArray" [DataKeys]="dataKeys" [itemsPerPage]="itemsPerPage" [pageNo]="pageNo" [sorting]="sortingHeaderList" [tableInfo]="tableInfo" [totalRecords]="totalRecordCount" (tableExpand)="childListData($event)" [isChildTableRequired]="false" (cancelClicked)="onCancelClick($event)" (reactivateClicked)="onReactivateClick($event)" (deleteClicked)="onDeleteClick($event)"></app-ds-table>
              </div>
              <span class="col-12 footer-pageControls">
                <span class="ds_c-renderCount">
                  <span>{{totalRecordCount === 0? '00':(((this.pageNo-1) * (itemsPerPage) + 1) === 1) ? '01' : ((this.pageNo-1) * (itemsPerPage) + 1)}} - {{((itemsPerPage) * (this.pageNo) > (this.totalRecordCount)) ? this.totalRecordCount : (itemsPerPage) * (this.pageNo)}} of {{this.totalRecordCount}}</span>
                  <app-select-drpdwn [Options]="[10, 20, 30]" [perPageRender]="this.itemsPerPage" (perPageValue)="renderPageValue($event)"></app-select-drpdwn>
                </span>
                <pagination-controls class=" ds_c-pagination" (pageChange)="onTablePage($event)"  [maxSize]="5"  (responsive)="true"  previousLabel=""
                nextLabel=""></pagination-controls>
              </span>
        </div>
    </section>
    <app-pop-ups [props]="{
      'content': this.cancelSubscriptionMessage,
      'heading': 'Cancel Subscription',
      'icon': './assets/table/unPublish.svg',
      'isIconZoomRequired':true,
      id:'cancel_subscription',
      'titleInfo':'A cancelled subscription can be reactivated again.'
    }"
    [isCancelButtonRequired]=true
    (cancelConfirmed)="onCancelSubscription($event)"></app-pop-ups>
    </div>
    <app-pop-ups [props]="{
      'content': this.reactivateSubscriptionMessage,
      'heading': 'Reactivate Subscription',
      'icon': '../../../../assets/table/reactivation.svg',
      'isIconZoomRequired':true,
      id:'reactivate_subscription',
      'titleInfo':'A reactivated subscription can be cancelled again.'
    }"
    [isCancelButtonRequired]=true
    (cancelConfirmed)="onReactivateSubscription($event)"></app-pop-ups>
    <app-pop-ups [props]="{
      'content': this.deleteSubscriptionMessage,
      'heading': 'Delete Subscription',
      'icon': '../../../../assets/table/bin.svg',
      'isIconZoomRequired':true,
      id:'delete_subscription',
      'titleInfo':'A deleted subscription cannot be restored again.'
    }"
    [isCancelButtonRequired]=true
    (cancelConfirmed)="onDeleteSubscription($event)"></app-pop-ups>
    <app-alert *ngIf="showSuccess" [success]="true">
      <div class="success_Icon"><img src="./assets/successIcon.svg"/><p>{{successMsg}}</p></div>   
    </app-alert>
    <app-alert *ngIf="showFailure" [failure]="true">
      <div class="unSuccess_Icon"><img src="../../../../assets/Health-monitor/Unhealthy.svg"/><p>{{failureMsg}}</p></div>   
    </app-alert>