import { FormGroup, FormControl,  } from '@angular/forms';
import { Component, ViewChildren, ElementRef, HostListener } from '@angular/core';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { DateFormatPipe } from 'src/app/pipes/date-format-pipe.pipe';
import { CustomCapitalizePipe } from 'src/app/pipes/capitalize-pipe/custom-capitalize-pipe.pipe';
import { SubscriptionApproverList } from 'src/app/shared/models/subscription-approver-list';
@Component({
  selector: 'app-my-approvals',
  templateUrl: './my-approvals.component.html',
  styleUrls: ['./my-approvals.component.scss'],
  providers: [DateFormatPipe,CustomCapitalizePipe]
})
export class MyApprovalsComponent {
  loaderEnabled: boolean = true;
  searchProductvalue: string = ''; 
  gridArray: any[]=[]; // type of gridArray is any as we need to pass that to generic table component
  selectedBusinessvalue: string[] = []; // for maintaining state of business Area filters
  dataKeys : string[]=[];
  pageNo: number = 1;
  itemsPerPage : number = 10;
  filterForm: FormGroup = new FormGroup({});
  businessFilter: FormControl = new FormControl('');
  totalRecordCount:number =0;
  isSearchBusinessFilterEnabled:boolean = false;
  tableInfo:string="pending approvals"
  enableColonDateFormat:boolean=false;
 
  apiTableHeaderList: string [] =[
   "Product Name",
   "Subscriber Name",
   "Application Number",
   "Application Name",
   "Business Area",
   "Application Owner",
   "State",
   "Date Requested",
   "Actions "
 ];
 
 childDetailHeaderList: string[]=[
   "Subscription Name",
 ]
 
 businessAreaList =[
   {
 'id': '1',
 'label': 'CT'
 },
 {
 'id': '2',
 'label': 'CO'
 },
 {
 'id': '3',
 'label': 'IT'
 },
 {
 'id': '4',
 'label': 'PT'
 },
 {
 'id': '5',
 'label': 'VT'
 }
 ];
 
 sortingHeaderList=[
  {head: 'Product Name', key: 'productName'},
  {head: 'Subscriber Name', key: 'subscriberEmail'},
   {head: 'Application Number', key: 'applicationNumber'}
 ,{head: 'Application Name', key: 'applicationName'},
  {head: 'Business Area', key: 'businessArea'},
   {head: 'Application Owner', key: 'applicationOwner'},
   {head: 'State', key: 'state'},
   {head: 'Date Requested', key: 'dateRequested'}
  ]
 
 searchProductSubscription!: Subscription;
 searchProductValueChanged:Subject<string> = new Subject<string>();
  @ViewChildren('contentSection') contentSections!: ElementRef[];
  @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
 sectionHeight = 0;
 // listen for window resize events
 @HostListener('window:resize')
 onResize() {
  this.setContentSectionHeight();
 }
 setContentSectionHeight() {
  const windowHeight = window.innerHeight;
 if(this.contentSections!=undefined){
   this.contentSections.forEach(section => {
     const offsetTop = section.nativeElement.getBoundingClientRect().top;      
     const sectionHeight = windowHeight - Math.floor(offsetTop) - (window.innerWidth * 0.0052 * 4.8);
     section.nativeElement.style.minHeight = `${Math.floor(sectionHeight)}px`;
   });
   }
 }
 
  constructor(private hipManagementService:Hip2ManagementService, private dateFormatPipe: DateFormatPipe,private capitalizePipe :CustomCapitalizePipe) { }
 
  public renderPageValue(value: number) {
    this.itemsPerPage = value;
    this.pageNo = 1;
    this.loadApprovalList()
   }
 
   ngOnInit(): void {
    this.loadApprovalList();
    this.filterForm = new FormGroup({
      filter: this.businessFilter
    });
    this.controlDataChange()   
  }
  ngAfterContentInit(): void {
    this.searchProductSubscription = this.searchProductValueChanged.pipe(debounceTime(1500)).subscribe(value=>{
     this.searchProductvalue=value;
     if(this.searchProductvalue.length>2 || this.searchProductvalue.length==0){
       this.pageNo=1;
       this.loadApprovalList();    
       this.setContentSectionHeight();  
         }   
    })
    this.setContentSectionHeight();
  }
  controlDataChange(){
    this.businessFilter.valueChanges.subscribe((businessFilterObject) => {
      let businessValue = businessFilterObject.map((obj:any)=>{return obj.label});     
     this.selectedBusinessvalue = businessValue;
     this.pageNo=1;
     this.loadApprovalList();    
    });   
  }
 
  onTablePage(event: any){
    this.pageNo = event;
    this.loadApprovalList()
  }
  loadApprovalList(){
   let filter= (this.searchProductvalue.trimStart()).trimEnd();
   let sortDirection = 'asc';
   let pageIndex = this.pageNo;
   let pageSize = this.itemsPerPage;
   let businessAreaCode = '';
   //get the selected business code
   this.selectedBusinessvalue.forEach(element => {
     businessAreaCode=businessAreaCode.length==0?element:(businessAreaCode+','+element)
   });  this.loaderEnabled=true;
   this.hipManagementService.getSubscriptionsApprovalsList(filter, sortDirection, pageIndex, pageSize,businessAreaCode).subscribe({
     next:(subscriptionsApprovalsData: SubscriptionApproverList) =>{
       this.gridArray=[];
       subscriptionsApprovalsData.approvalList.forEach((subscriptionsApprovalDetail:any) => {
         let childTableInformation ={
         "subscriptionName": subscriptionsApprovalDetail.subscriptionName
         }
         let childTableData=[childTableInformation];
         let api={
           "uniqueId":subscriptionsApprovalDetail.id,
           "productName":subscriptionsApprovalDetail.productName,
           "subscriberEmail":this.capitalizePipe.transform(subscriptionsApprovalDetail.subscriberName),
           "applicationNumber":subscriptionsApprovalDetail.applicationNumber,           
           "applicationName":subscriptionsApprovalDetail.applicationName,
           "businessArea":subscriptionsApprovalDetail.businessArea,
           "applicationOwner":subscriptionsApprovalDetail.applicationOwner,
           "state":subscriptionsApprovalDetail.state=="submitted"?"Pending Approval":subscriptionsApprovalDetail.state,
           "dateRequested":subscriptionsApprovalDetail.createdOn==undefined?"":this.dateFormatPipe.transform(subscriptionsApprovalDetail.createdOn,this.enableColonDateFormat),           
           "Action": 
                   {  
                    "info": "/publisher/my-approvals/view-pending-subscription-approval/"+subscriptionsApprovalDetail.subscriptionName+"/"+subscriptionsApprovalDetail.apimProductId,
                  },
            "subscriptionDisplayName" : subscriptionsApprovalDetail.subscriptionDisplayName,
            "detail":{
             "headings":this.childDetailHeaderList,
             "tableData":childTableData
            }            
         }
         this.gridArray.push(api);
       });
       if(this.gridArray.length>0){
         this.dataKeys = Object.keys(this.gridArray[0]);
         this.dataKeys.splice(this.dataKeys.indexOf("detail",1));         
         this.dataKeys.splice(this.dataKeys.indexOf("subscriptionDisplayName"));
       }
       this.loaderEnabled=false;
       this.totalRecordCount=subscriptionsApprovalsData.totalCount;
       this.resetScroll();
       this.setContentSectionHeight();
     },
     error: (err: string) => {console.log("Error response " + err);this.loaderEnabled=false;
       this.setContentSectionHeight();
   }
   })
  }
 
  productSearch(apiValue: string){
   this.searchProductValueChanged.next(apiValue)
  }
  resetScroll(){    
    const BodyElement: HTMLElement | null = document.getElementById('bodyContent');
    if(BodyElement != null ){
      BodyElement.scrollTop = 0;
    }
  }
  clearSearch(){
    if(this.searchProductvalue.length>2){
      this.searchProductValueChanged.next("");
    }
    this.searchProductvalue="";
  }
 }
 
