import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {Environment} from './environments/environment'


if(Environment.environment=='dev'){
  var elem= document.createElement('script');
  elem.src="https://www.googletagmanager.com/gtag/js?id=G-1X0X3BZZ7W";
  elem.async=true;
 document.head.appendChild(elem);
 var gtagScript= document.createElement('script');
 gtagScript.text="window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments)}gtag('js', new Date()); gtag('config', 'G-1X0X3BZZ7W');";
 document.getElementsByTagName('head')[0].appendChild(gtagScript);
 document.head.appendChild(gtagScript);
}else if(Environment.environment=='tst'){
  var elem= document.createElement('script');
  elem.src="https://www.googletagmanager.com/gtag/js?id=G-13XN2KK8N4";
  elem.async=true;
 document.head.appendChild(elem);
 var gtagScript= document.createElement('script');
 gtagScript.text="window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-13XN2KK8N4');";
 document.getElementsByTagName('head')[0].appendChild(gtagScript);
 document.head.appendChild(gtagScript);
}else if(Environment.environment=='prd'){
  var elem= document.createElement('script');
  elem.src="https://www.googletagmanager.com/gtag/js?id=G-1FJ69W1L13";
  elem.async=true;
 document.head.appendChild(elem);
 var gtagScript= document.createElement('script');
 gtagScript.text="window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-1FJ69W1L13');";
 document.getElementsByTagName('head')[0].appendChild(gtagScript);
 document.head.appendChild(gtagScript);
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
