<div #bodyWrapper class="section">
    <app-bread-crumb [links]="[
    {
      'href': '/developer/all-products',
      'title': 'Products & APIs', 
      'description': 'An overview of all Products and APIs to subscribe to as a developer.'
    }
    ]"></app-bread-crumb>
    <!-- <p class="paragraph">Page is currently under development</p> -->
    <section class="content-section container"  #contentSection>
 
      <div class="ds_c-listing-table">
        <div class="ds_c-listing-table-filter col-sm-12">
          <div class="navbar col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
            <span class="col-12 col-sm-12 col-md-4" style="position: relative">
              <input #searchProductField
                type="text"
                class="form-control text-field-responsive"
                name="Api_name"
                [(ngModel)]="productName"
                (ngModelChange)="searchProduct($event)"
                placeholder="Search with at least 3 characters"
                (keydown.enter)="searchProductField.blur()"
              />
              <span class="search-icon-placement" *ngIf="productName.length==0"></span>
              <span *ngIf="productName.length > 0" class="removeBtn-search" (click)="clearSearch()" ></span>

            </span>
            <span [formGroup]="FilterForm" class="col-12 col-sm-12 col-md-7 filter-placement">
                <app-multi-select-dropdown class="multi-select" [props]="{placeholder: 'Filter on business area'}" [control]="BusinessFilter" [isSearchEnabled]="false" 
                [dataSets]="businessAreaList"></app-multi-select-dropdown>
              </span>
            
          
          </div>
          <span class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 pageControls">
            <span class="ds_c-renderCount">
              <span>{{totalRecordCount === 0? '00':(((this.pageNo-1) * (itemsPerPage) + 1) === 1) ? '01' : ((this.pageNo-1) * (itemsPerPage) + 1)}} - {{((itemsPerPage) * (this.pageNo) > (this.totalRecordCount)) ? this.totalRecordCount : (itemsPerPage) * (this.pageNo)}} of {{this.totalRecordCount}}</span>
              <app-select-drpdwn [Options]="[12, 24, 36]" [perPageRender]="this.itemsPerPage" (perPageValue)="renderPageValue($event)"></app-select-drpdwn>
            </span>            
            <pagination-controls class=" ds_c-pagination" (pageChange)="onTablePage($event)"  [maxSize]="5"  (responsive)="true"  previousLabel=""
            nextLabel=""></pagination-controls>
          </span>
        </div>
        <div style="overflow-x: auto;">
          <app-shimmer-card *ngIf="this.loaderEnabled" [isCardList]="true"></app-shimmer-card>
        </div> 
        <div class="card-list-wrapper" *ngIf="!this.loaderEnabled">
          <app-product-listing-card [dataset]="gridArray" [itemsPerPage]="itemsPerPage" [pageNo]="pageNo" [totalRecords]="totalRecordCount" [productApis]="productApis" (tagClickEvent)="onTagClick($event)"></app-product-listing-card>
          <app-general-loader *ngIf="pendingChildAPICall>0 && !this.loaderEnabled"></app-general-loader>
        </div>
      </div>
      <span class="col-12 footer-pageControls">
        <span class="ds_c-renderCount">
          <span>{{totalRecordCount === 0? '00':(((this.pageNo-1) * (itemsPerPage) + 1) === 1) ? '01' : ((this.pageNo-1) * (itemsPerPage) + 1)}} - {{((itemsPerPage) * (this.pageNo) > (this.totalRecordCount)) ? this.totalRecordCount : (itemsPerPage) * (this.pageNo)}} of {{this.totalRecordCount}}</span>
          <app-select-drpdwn [Options]="[12, 24, 36]" [perPageRender]="this.itemsPerPage" (perPageValue)="renderPageValue($event)"></app-select-drpdwn>
        </span>
        <pagination-controls class=" ds_c-pagination" (pageChange)="onTablePage($event)"  [maxSize]="5"  (responsive)="true"  previousLabel=""
        nextLabel=""></pagination-controls>
      </span>
    </section>
  </div>