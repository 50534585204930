<table cellspacing="0" class="table_64">
    <thead>
      <tr class="header-col">
        <!-- <th *ngFor="let heading of HeadArray; let i = index">
         <ng-container *ngIf="sorting.includes(DataKeys[i]); else head"> <span class="sortHeader"  (click)="sort(DataKeys[i])"> {{ heading}} &nbsp;&nbsp;<span class="tableHeadIcon"><img [class.reverse]="reverse" src="../../../assets/table/arrow-dwn.svg"></span></span></ng-container>
          <ng-template #head>{{heading}}</ng-template>
          
        </th> -->
        <th *ngFor="let heading of HeadArray; let i = index">
          <ng-container *ngIf="sortHeaderList.includes(heading);else head">
          <ng-container *ngFor="let obj of sorting">
              <ng-container *ngIf="obj.head == heading;">
              <ng-container *ngFor="let sortObject of sortFieldValues">
              <ng-container *ngIf="sortObject.header==heading">
                <span class="sortHeader"  (click)="sort(obj.key,sortObject.isReverse)"> {{ heading }}
                  <ng-container *ngIf="sortObject.isVisible">
                  &nbsp;&nbsp;
                  <span class="tableHeadIcon">
                    <img [class.reverse]="sortObject.isReverse" src="../../../assets/table/arrow-down.svg" >
                  </span>
                </ng-container>
              </span>
              </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          </ng-container> 
          <ng-template #head>{{heading}}</ng-template>
         </th>
      </tr>
    </thead>
    <!-- <i class="fa-solid fa-arrow-down-long"></i> for arrow  <span *ngIf="reverse"><i  class="fa-solid fa-arrow-up-long"></i></span> -->
    <ng-container *ngIf="GridArray.length > 0; else noData" >
    
    <tbody  class="accordion accordion-flush bodytable" id="accordionFlushExample">
        <ng-container
          *ngFor="
            let data of GridArray
              | orderBy : key : reverse
              | paginate : { itemsPerPage: itemsPerPage, currentPage: pageNo, totalItems: totalRecords};
            let i = index
          "
        >
        <tr>
          <ng-container *ngFor="let key of DataKeys; let j = index">
            <!-- {{key}}{{data|json}} -->
            <td *ngIf="j != DataKeys.length  && key != 'uniqueId'" [class.blue-content]="j === 1 && isChildTableRequired" [class.action]="key == 'Action'">
              
                <ng-container *ngIf="key != 'Action'; else action">
                  <span *ngIf="j === 1 && isChildTableRequired" class="caret" (click)="renderApiDetails(data);data.isExpanded = !data.isExpanded" [class.isExpanded]="data.isExpanded">                    
                    <svg class="bs-caret-right" width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M-5.27643e-08 1.20711C-3.3293e-08 0.761654 0.538571 0.538571 0.853553 0.853553L4.64645 4.64645C4.84171 4.84171 4.84171 5.15829 4.64645 5.35355L0.853552 9.14645C0.53857 9.46143 -4.03821e-07 9.23835 -3.8435e-07 8.79289L-5.27643e-08 1.20711Z" fill="#37717B"/>
                      </svg>                      
                    &nbsp;&nbsp;{{data[key]}}</span>
                    <ng-container *ngIf="j==1 && !isChildTableRequired">{{data[key]}}</ng-container>
                <ng-container *ngIf="j!=1">
                <ng-container *ngIf="key == 'requireSubscription'; else normalField" >
                  <span style="padding-left: 1.5rem;">
                  <svg  *ngIf="data[key]==true" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.6 19.3879L9.50607 15.2939L9.4 15.1879L9.29393 15.2939L7.89393 16.6939L7.78787 16.8L7.89393 16.9061L13.4939 22.5061L13.6 22.6121L13.7061 22.5061L25.7061 10.5061L25.8121 10.4L25.7061 10.2939L24.3061 8.89393L24.2 8.78787L24.0939 8.89393L13.6 19.3879Z" fill="#7D8083" stroke="#7D8083" stroke-width="0.3"/>
                  </svg>
                  <svg  *ngIf="data[key]==false" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.1061 10.5161L23.2121 10.41L23.1061 10.3039L21.6961 8.89393L21.59 8.78787L21.4839 8.89393L16 14.3779L10.5161 8.89393L10.41 8.78787L10.3039 8.89393L8.89393 10.3039L8.78787 10.41L8.89393 10.5161L14.3779 16L8.89393 21.4839L8.78787 21.59L8.89393 21.6961L10.3039 23.1061L10.41 23.2121L10.5161 23.1061L16 17.6221L21.4839 23.1061L21.59 23.2121L21.6961 23.1061L23.1061 21.6961L23.2121 21.59L23.1061 21.4839L17.6221 16L23.1061 10.5161Z" fill="#7D8083" stroke="#7D8083" stroke-width="0.3"/>
                    </svg>
                  </span>                    
                </ng-container>
                <ng-template #normalField>
                  {{data[key]}}
                </ng-template>
                </ng-container>
                </ng-container>
                <!-- <i [class.fa-rotate-90]="data.isExpanded" class="fa-solid fa-caret-right"></i> for right arrow svg -->
              <ng-template #action>
                <!-- the below links need to implement in future -->
                <!-- <span *ngIf="data[key].publish"><a  [routerLink]="'/publish-' + data.uniqueID"><img src="../../../assets/table/publish.svg"></a></span> -->
                <span *ngIf="data[key].edit"><a  [routerLink]="data[key].edit" [state]="{parentData: data}">
                  <svg width="32" height="32" viewBox="0 0 32 32"  xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 20L15 24H25V20H19Z" />
                    <path d="M16.06 11.1904L7 20.2504V24.0004H10.75L19.81 14.9404L16.06 11.1904ZM9.92 22.0004H9V21.0804L16.06 14.0204L16.98 14.9404L9.92 22.0004Z"/>
                    <path d="M22.7109 12.04C23.1009 11.65 23.1009 11.02 22.7109 10.63L20.3709 8.29C20.1709 8.09 19.9209 8 19.6609 8C19.4109 8 19.1509 8.1 18.9609 8.29L17.1309 10.12L20.8809 13.87L22.7109 12.04Z" />
                    </svg>                    
                </a></span>
                <span *ngIf="data[key].info">
                  <a  [routerLink]="data[key].info" [state]="{parentData: data}" *ngIf="data[key].isQueryParamsReqForInfoLink == undefined">
                  <svg width="32" height="32" viewBox="0 0 32 32"  xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.6094 11H17.6094V13H15.6094V11ZM15.6094 15H17.6094V21H15.6094V15ZM16.6094 6C11.0894 6 6.60938 10.48 6.60938 16C6.60938 21.52 11.0894 26 16.6094 26C22.1294 26 26.6094 21.52 26.6094 16C26.6094 10.48 22.1294 6 16.6094 6ZM16.6094 24C12.1994 24 8.60938 20.41 8.60938 16C8.60938 11.59 12.1994 8 16.6094 8C21.0194 8 24.6094 11.59 24.6094 16C24.6094 20.41 21.0194 24 16.6094 24Z"/>
                    </svg>                    
                </a>
                <a  [routerLink]="data[key].info" [state]="{parentData: data}" [queryParams]="data[key].queryparamsforInfoLink" *ngIf="data[key].isQueryParamsReqForInfoLink != undefined && data[key].isQueryParamsReqForInfoLink == true">
                  <svg width="32" height="32" viewBox="0 0 32 32"  xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.6094 11H17.6094V13H15.6094V11ZM15.6094 15H17.6094V21H15.6094V15ZM16.6094 6C11.0894 6 6.60938 10.48 6.60938 16C6.60938 21.52 11.0894 26 16.6094 26C22.1294 26 26.6094 21.52 26.6094 16C26.6094 10.48 22.1294 6 16.6094 6ZM16.6094 24C12.1994 24 8.60938 20.41 8.60938 16C8.60938 11.59 12.1994 8 16.6094 8C21.0194 8 24.6094 11.59 24.6094 16C24.6094 20.41 21.0194 24 16.6094 24Z"/>
                    </svg>                    
                </a></span>
                <a class="icon" (click)="OnCancelClick($event,data)" *ngIf="data[key].cancel">
                  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path class="cls-2" d="M12.55 9.12L11.1 7.66C12.68 6.61 14.57 6 16.61 6C22.13 6 26.61 10.48 26.61 16C26.61 18.04 26 19.93 24.95 21.51L23.49 20.05C24.2 18.86 24.61 17.48 24.61 16C24.61 11.59 21.02 8 16.61 8C15.13 8 13.75 8.41 12.55 9.12ZM22.27 13.53L20.86 12.12L18.21 14.77L19.62 16.18L22.27 13.53ZM24.39 26.61L22.12 24.34C20.54 25.39 18.65 26 16.61 26C11.09 26 6.61 21.52 6.61 16C6.61 13.96 7.22 12.07 8.27 10.49L6 8.22L7.41 6.81L25.79 25.19L24.39 26.61ZM20.67 22.88L16.79 19L14.62 21.12L10.38 16.88L11.79 15.47L14.62 18.3L15.38 17.59L9.73 11.94C9.02 13.14 8.61 14.52 8.61 16C8.61 20.41 12.2 24 16.61 24C18.09 24 19.47 23.59 20.67 22.88Z" />
                    </svg>                                                          
                </a>
                <span *ngIf="data[key].reactivate"><a class="icon" (click)="OnReactivateClick($event,data)">
                  <img  width="32" height="32" src="../../../../assets/table/reactivation.svg">
                </a></span>
                <span *ngIf="data[key].delete"><a class="icon" (click)="OnDeleteClick($event,data)">
                  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 13V23H12V13H20ZM18.5 7H13.5L12.5 8H9V10H23V8H19.5L18.5 7ZM22 11H10V23C10 24.1 10.9 25 12 25H20C21.1 25 22 24.1 22 23V11Z"/>
                    </svg>                    
                </a></span>
                <span *ngIf="data[key].download">
                  <a href="{{ data[key].download }}" (click)="downloadSubscription($event,data[key] )">
                    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path  d="m4.08,22c-.56,0-1.05-.21-1.46-.62s-.62-.9-.62-1.46v-4.23h2.08v4.23h15.83v-4.23h2.08v4.23c0,.56-.21,1.05-.62,1.46s-.9.62-1.46.62H4.08Zm7.92-4.85l-6.1-6.1,1.48-1.5,3.58,3.58V2h2.08v11.13l3.58-3.58,1.48,1.5-6.1,6.1Z"/>
                </svg>
              </a></span>
              <a class="icon" (click)="onUnPublishClick($event,data)" *ngIf="data[key].unpublish">
                <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path class="cls-2" d="M12.55 9.12L11.1 7.66C12.68 6.61 14.57 6 16.61 6C22.13 6 26.61 10.48 26.61 16C26.61 18.04 26 19.93 24.95 21.51L23.49 20.05C24.2 18.86 24.61 17.48 24.61 16C24.61 11.59 21.02 8 16.61 8C15.13 8 13.75 8.41 12.55 9.12ZM22.27 13.53L20.86 12.12L18.21 14.77L19.62 16.18L22.27 13.53ZM24.39 26.61L22.12 24.34C20.54 25.39 18.65 26 16.61 26C11.09 26 6.61 21.52 6.61 16C6.61 13.96 7.22 12.07 8.27 10.49L6 8.22L7.41 6.81L25.79 25.19L24.39 26.61ZM20.67 22.88L16.79 19L14.62 21.12L10.38 16.88L11.79 15.47L14.62 18.3L15.38 17.59L9.73 11.94C9.02 13.14 8.61 14.52 8.61 16C8.61 20.41 12.2 24 16.61 24C18.09 24 19.47 23.59 20.67 22.88Z" />
                  </svg>                                                          
              </a>
              </ng-template>
          
              
              
              
              
            </td>
          </ng-container>
        </tr>
        <tr
            id="flush-collapseOne"
            class="toggle-row"
            [class.active]="data.isExpanded"
          >
            <td colspan="10">
              <div>
                <table *ngIf="isChildTableRequired">               
                    <ng-container *ngIf="data.detail.tableData.length>0;else noChildData">
                  <thead>
                    <tr>
                      <th *ngFor="let heading of data.detail.headings">
                        {{ heading }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr *ngFor="let insideData of data.Detail.tableData">
                      
                      <td>
                        <a href="#" class="link_secondary">{{insideData.API}}</a>
                      </td>
                      <td>{{ insideData.Version }}</td>
                      <td>{{ insideData.desc }}</td>
                      
                    </tr> -->
                    <!-- Updated on 7th April 2023 replaced above code with below one -->
                    <ng-container *ngIf="tableDetailLink; else simpleDetailTable" >
                      <tr *ngFor="let insideData of data.detail.tableData" >
                        {{insideData[key]}}
                        <ng-container *ngFor="let key of this.insideTableKey; let j = index">
                            <ng-container *ngIf="j === 0; else normalTd">
                            <td>
                              <a  class="link_secondary" (click)="onChildLinkClick(insideData)">{{insideData[key]}}</a>
                            </td>
  
                          </ng-container>
                          <ng-template #normalTd>
                            <td>{{insideData[key]}}</td>
    
                          </ng-template>
                          
                        </ng-container>
                        
                       
                      </tr>
                    </ng-container>
                    <ng-template #simpleDetailTable>
                      <tr *ngFor="let insideData of data.detail.tableData" >
                        <ng-container *ngFor="let key of this.insideTableKey; let j = index">
                            <td>{{insideData[key]}}</td>
                        </ng-container>
                        
                       
                      </tr>
                    </ng-template>
                    
                    
                    
                  </tbody>
               
                </ng-container>
              

                  <ng-template #noChildData>
                    <ng-container *ngIf="isLoadingChildData && data?.Action?.apimProductName==productName;else displayNoDataMessage">
                    <p class="text-paragraph">Loading</p>
                     </ng-container>
                  <ng-template #displayNoDataMessage>
                    <p class="text-paragraph">No {{childDataInfo}} found</p>
                  </ng-template>
                    </ng-template>
                </table>
              </div>
            </td>
          </tr>
        </ng-container>
    </tbody>
  </ng-container>
  <ng-template #noData>
    
    <tbody>
      <tr>
        <td [attr.colspan]="HeadArray.length" class="NoData">
          <div>
          <ng-container
          *ngFor="
            let data of GridArray
              | orderBy : key : reverse
              | paginate : { itemsPerPage: itemsPerPage, currentPage: pageNo, totalItems: totalRecords};
            let i = index
          "
        >
          </ng-container>
          <p class="text-message">No  {{tableInfo}} found</p>
        </div>
        </td>
      </tr>
    </tbody>
  </ng-template>
         
</table>