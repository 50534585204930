import { AfterContentInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject, debounceTime, timer } from 'rxjs';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { StateService } from 'src/app/shared/services/state.service';
import { Environment } from 'src/environments/environment';
import { CreateProductModel, CreateDraftProductModel } from '../../models/product-model';
import { ProductDetails } from '../models/product-details';
import { User } from '../models/user';

@Component({
  selector: 'app-edit-draft-product',
  templateUrl: './edit-draft-product.component.html',
  styleUrls: ['./edit-draft-product.component.scss']
})
export class EditDraftProductComponent implements OnInit, AfterContentInit, OnDestroy{
  breadCrumbProductId:string = '';
  breadCrumbProduct:string = '';
  createProduct!: CreateProductModel;  
  applicationId:string='';
  subscriptions: Subscription[] = [];
  approverRequired:string = '';
  businessArea:string = "";
  appRegDataSet:any[] = [];
  approversDataSet:any[] = [];
  appRegistrationOwnersDataSet:any[]=[];
  apisDataSet:any[]=[];
  defaultApisDataSet: any[]=[];
  applicationDataSet:any[]=[];
  informationData:any[]=[];
  checkError: boolean = false;
  showSuccess:boolean = false;
  showFailure:boolean = false;
  successFull:boolean = false;
  failure:boolean = false;
  successMsg:string='';
  failureMsg:string='';
  feedbackActive: boolean = false;
  loaderActive: boolean = false;
  disableApprover:boolean = false;  
  isCustomOptionForApproverRequired:boolean=true;
  isCustomOptionForAppRegOwnersRequired:boolean=true;
  ProductForm : FormGroup = new FormGroup({});
  basicTabProductForm: FormGroup = new FormGroup({});
  managementTabProductForm: FormGroup = new FormGroup({});
  published:FormControl= new FormControl('');
  requiredSubscription:FormControl= new FormControl('');
  requiredApproval:FormControl= new FormControl('');
  RadioWrapper: FormGroup = new FormGroup({
    Published: this.published,
        RequiredSubsc: this.requiredSubscription,
        RequiredApproval: this.requiredApproval
  });  
  
  AppNameAndNumber : FormControl = new FormControl('');
  Product = new  FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(59)]);
  APIs = new FormControl([], [Validators.required, Validators.minLength(1)]);
  ProdAppName= new FormControl('', [Validators.required, Validators.minLength(2)]);
  ProductId = new FormControl('', [Validators.maxLength(84)]);
  AppOwner = new FormControl('', null);  
  AppNumber = new FormControl('');
  Approvers = new FormControl([]);
  appRegOwners = new FormControl([],[Validators.required])
  description = new FormControl('', [Validators.required]);
  productAppRegName = new FormControl('');
  applicationSubject: Subject<string> = new Subject();
  apiSubject: Subject<string> = new Subject();
  appRegSubject: Subject<string> = new Subject();
  userSubject: Subject<string> = new Subject();
  appRegistrationOwnerSubject: Subject<string> = new Subject();
  pageInfo:string="A Product is a collection of one or more APIs. Approvers can be assigned to keep control over any new Product subscriptions. Once approved, a subscribing application gets access to all APIs inside a Product. \n\n You are about to edit an existing Product in Azure API Management."
  productAppRegNameValue:string = '';
  isBasicTabActivated:boolean = false;
  isManagementTabActivated:boolean = false;
  isReviewTabActivated:boolean = false;
  isSubmitted:boolean = false;
  draftProductLabel:string='Draft Product';
  isCustomLabelEnabled:boolean=true;
  draftProductInfo:string='Create only a draft version of the product, with a minimum of information, not visible for subscribers';
  isDraftProduct:boolean=true;
  draftProductOption:any[]=[{label:this.draftProductLabel}];
  productAppRegistrationTooltip = 'We would like to remind you of the available documentation about "<a href="https://ac-its.atlassian.net/wiki/spaces/AHIP/pages/22216724/How+to+create+an+Application+Registration+for+a+Product+Subscription" target="_blank" style="font-size:inherit">How to create/configure an App Registration?</a>"'
  cmdbId!: string;
  approversValue: any;
  apisValue: any;
  showAPIs!: boolean;
  showApprovers!: boolean;
  errorHandleService: any;
  isApproverMandateOnly:boolean=false;
  constructor(private hipManagementService: Hip2ManagementService,private router:Router,
    private stateservice: StateService, private route:ActivatedRoute){
      let productInfo=this.router.getCurrentNavigation()?.extras.state??{parentData:""};
      console.log('productInfo',productInfo['parentData']?.productName);
      this.breadCrumbProduct=productInfo['parentData']?.productName??"";
    }
  
  ngOnInit(): void {
    this.breadCrumbProductId = this.route.snapshot.paramMap.get('productId') ?? "";
    this.ProductForm = new FormGroup({
      Product: this.Product,
      ProductId: this.ProductId,
      AppNameAndNumber: this.AppNameAndNumber,
      AppOwner: this.AppOwner,
      Description: this.description ,
      APIs: this.APIs,            
      Approvers: this.Approvers,
      AppRegistrationOwners: this.appRegOwners,      
      RadioWrapper: this.RadioWrapper      
    });
  this.basicTabProductForm = new FormGroup({
    Product: this.Product,
    ProductId: this.ProductId,
    AppNameAndNumber: this.AppNameAndNumber,
    Description: this.description,
    APIs: this.APIs
  });
  this.managementTabProductForm = new FormGroup({
    AppRegistrationOwners: this.appRegOwners      
  });
  this.getProductDetailsByProductId(this.breadCrumbProductId);
  this.onApiChanges();
  }

  setFormValue(objProduct:ProductDetails){
    this.businessArea = objProduct.businessAreaCode;
    this.cmdbId = objProduct.cmdbId;
    this.breadCrumbProduct = objProduct.productName;
    sessionStorage.setItem(this.route.snapshot.paramMap.get('productId') ?? "",this.breadCrumbProduct);
    this.informationData = [
      {'title':'Application Name', 'value':objProduct.applicationName },
      {'title':'Application Number', 'value': objProduct.cmdbId },          
      {'title':'Business Area', 'value': objProduct.businessAreaCode },
      {'title':'Unit Code', 'value': objProduct.unitCode },
      {'title':'Cost Center', 'value': objProduct.costCenter }
    ];             
   
    objProduct.approvers.forEach(
      element => {
        this.hipManagementService.getListOfADUsers_Approvers(element).subscribe({
         next:(response)=>{
          response.forEach((userData: User) => {
            this.approversValue.push({
              "id":userData.email,
              "label":userData.name
            })
          });
          this.ProductForm.patchValue({
            Approvers: this.approversValue,
          })
         }
        })
        
      }
    )
  
    objProduct.apis.forEach(
      element => {
        this.apisValue.push({
          "id":element.apimApiName,
          "label":element.name
        })
      }
    )
    this.ProductForm.patchValue({
      Product : objProduct.productName,
      ProductId: objProduct.apimProductName,
      Description: objProduct.description,
      AppOwner:objProduct.appOwner,
      AppNameAndNumber: objProduct.applicationName+ " / "+ objProduct.cmdbId,
      APIs: this.APIs,    
      Approvers: this.approversValue,
      RadioWrapper: {
        "Published": objProduct.state.toLowerCase() == "published" ? "Yes" : "No",
        "RequiredSubsc": objProduct.subscriptionRequired ? "Yes" : "No",
        "RequiredApproval": objProduct.approvalRequired ? "Yes" : "No"
      }
    })
    this.basicTabProductForm.patchValue({
      Product : objProduct.productName,
      ProductId: objProduct.apimProductName,
      Description: objProduct.description,
      AppNameAndNumber: objProduct.applicationName+ " / "+ objProduct.cmdbId,
      APIs:this.APIs
    });
    let appName=objProduct.applicationName+ " / "+ objProduct.cmdbId;
    this.applicationId=objProduct.cmdbId;
    this.AppNameAndNumber.setValue(appName);
    if(objProduct.approvalRequired) {
      this.setApproverMandate();
    }
    else {
      this.removeApproverMandate();
    }
    if(objProduct.isDraftProduct){
      this.draftProductOption.push({label:this.draftProductLabel});
    }
    this.showAPIs=true;
    this.showApprovers = true;  
   }
   getProductDetailsByProductId(apimProductId: string) {
    this.loaderActive = true;
    this.hipManagementService.getProductDetailsByProductId(apimProductId).subscribe({
      next:(response:ProductDetails)=> {
        this.loaderActive = false;
        if(response.hasAccess){
          this.setFormValue(response);
          this.loadApis(response.cmdbId);
        }else{
          this.router.navigate(['/unauthorized']);
        }      
      },
      error:(error:any)=>{
        this.loaderActive = false;
        this.errorHandleService.handleError(error);
        }    
    });
  }
  
  setProductAppRegValue(appRegId:string){
    this.loaderActive = true;
    this.hipManagementService.getAdApplicationsByAppId(appRegId).subscribe({
      next:(response:any)=> {
        this.loaderActive = false;
        let clientAppName = response[0].clientAppName;      
        this.ProductForm.patchValue({
          ProdAppName: clientAppName,
        })
      },
      error:(error:any)=>{
        this.loaderActive = false;
      }  
    });
   }

  resetApplicationInfo(){
    this.AppOwner.setValue('');
    this.ProductId.setValue('');          
    this.businessArea='';    
    this.informationData=[]; 
  }

  getCmdbDetails(cmdbId:string){
    this.loaderActive = true;
    this.hipManagementService.getCmdbDetails(cmdbId).subscribe({ 
      next:(response:any)=>{
        this.loaderActive = false;
        if (response !== null){
          this.AppOwner.setValue(response.applicationOwner);
          this.businessArea = response.businessArea;        
          this.informationData = [
            {'title':'Application Name', 'value':this.AppNameAndNumber.value.value },
            {'title':'Application Number', 'value':this.AppNameAndNumber.value.id },          
            {'title':'Business Area', 'value': response.businessArea },
            {'title':'Unit Code', 'value': response.familyCode },
            {'title':'Cost Center', 'value': response.costCenter }
          ];               
        }      
      },
      error:(error:any)=>{
        this.loaderActive = false;        
      }           
    });
  }

  applicationDataBind(data:any){
    this.applicationDataSet=[];
    data.forEach((element: { applicationId: any; applicationName: any; }) => {
      this.applicationDataSet.push({
        'id':element.applicationId,
        'value':element.applicationName                
      })
    })
  } 
  
  apisDataBind(data:any){
    this.apisDataSet=[];
    this.defaultApisDataSet = [];
    data.forEach((element: { displayName: any; apimApiId: any; }) => {
      this.apisDataSet.push({
        'id':element.apimApiId,
        'label':element.displayName
      })
    })
    this.defaultApisDataSet=this.apisDataSet;    
  }

  getAdApplications(appName: string):any {
    this.hipManagementService.getAdApplications(appName,this.applicationId).subscribe({
        next:(response:any)=> {
          if(response.length > 0){
            this.appRegistrationDataBind(response);          
          }
          else {
            this.appRegDataSet =[];
          }          
        },
        error:(error:any)=>{          
          this.appRegDataSet =[];
        }  
    });
  }

  appRegistrationDataBind(data:any){   
    this.appRegDataSet=[];
    data.forEach((element: { clientAppName: any; appId: any; }) => {      
      this.appRegDataSet.push({                      
        'id': element.appId,
        'value': element.clientAppName                
      });  
    });
  }

  getAdUsers(searchText: string):any {    
    this.hipManagementService.getListOfADUsers_Approvers(searchText).subscribe({  
      next:(response:any)=>{
        if(response.length > 0) {
          this.approversDataBind(response);              
        }
        else {          
          this.approversDataSet =[];
        }        
      },
      error:(error:any)=>{      
        this.approversDataSet =[];
      }     
    });
  }

  getAppRegistrationOwners(searchText: string):any {    
    this.hipManagementService.getListOfADUsersOwners(searchText).subscribe({  
      next:(response:any)=>{
        if(response.length > 0) {
          this.appRegistrationOwnersDataBind(response);              
        }
        else {          
          this.appRegistrationOwnersDataSet =[];
        }        
      },
      error:(error:any)=>{      
        this.appRegistrationOwnersDataSet =[];
      }     
    });
  }

  appRegistrationOwnersDataBind(data:any) {
    this.appRegistrationOwnersDataSet=[];
    data.forEach((element: { email: any; name: any; }) => {      
      this.appRegistrationOwnersDataSet.push({                      
        'id': element.email,
        'label': element.name ,
        'customLabel': element.name + ' ['+element.email+']'                
      });  
    });
  }

  approversDataBind(data:any) {
    this.approversDataSet=[];
    data.forEach((element: { email: any; name: any; }) => {      
      this.approversDataSet.push({                      
        'id': element.email,
        'label': element.name ,
        'customLabel': element.name + ' ['+element.email+']'                
      });  
    });
  }

  enableApproval(event: any){
  if(event) {
    if(event.toLowerCase() == 'yes'){      
      this.disableApprover = false;
      this.setApproverMandate();
    }else if(event.toLowerCase() == 'no'){      
      this.disableApprover = true;
      this.removeApproverMandate();
    }else{
      this.disableApprover = false;           
      this.setApproverMandate();
    }    
  }
}

setApproverMandate(){
  this.requiredApproval.setValue('Yes');
  this.approverRequired = 'yes';
  this.Approvers.setValidators([Validators.required]);
  this.Approvers.updateValueAndValidity();
}
removeApproverMandate(){  
  this.requiredApproval.setValue('No');  
  this.Approvers.clearValidators();
  this.Approvers.updateValueAndValidity(); 
  this.approverRequired = 'no';
}
  
 

   applicationSearch(searchTerm: string){     
      this.applicationSubject.next(searchTerm);       
   }

   ngAfterContentInit() : void {       

    this.subscriptions.push(this.userSubject.pipe(debounceTime(1500)).subscribe(value =>{      
      if (value.length > 2) {
        this.getAdUsers(value);          
      }
      else {
        this.approversDataSet = [];
      }      
    }));     

    this.subscriptions.push(this.appRegistrationOwnerSubject.pipe(debounceTime(1500)).subscribe(value =>{
      if(value.length > 2){
          this.getAppRegistrationOwners(value);
      } else{
        this.appRegistrationOwnersDataSet = [];
      }
    }))
   }



   apiSearch(searchTerm: string){             
    if(searchTerm.length) {
      this.apisDataSet = [];
      for (let i = 0; i < this.defaultApisDataSet.length; i++){            
        if(this.defaultApisDataSet[i].label?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          this.apisDataSet.push(this.defaultApisDataSet[i]);                  
        }
      }
    }
    else {
      this.apisDataSet = this.defaultApisDataSet;
    }
   }

  loadApis(appId: string){   
    this.loaderActive=true;
    this.hipManagementService.getAllApimApis(appId).subscribe(
        {          
          next: (api:any) => {                        
            this.loaderActive = false;
            if(api.length > 0) {
              this.apisDataBind(api);
            }
            else{              
              this.apisDataSet = [];
              this.defaultApisDataSet=[];
            }            
          },
          error: (err: string) => {            
            this.loaderActive = false;
            this.apisDataSet = [];
            this.defaultApisDataSet=[];
          }
        });

   }

   userSearch(searchTerm: string){        
    this.userSubject.next(searchTerm);      
   }
  
   appRegistrationOwnerSearch(searchTerm: string){
     this.appRegistrationOwnerSubject.next(searchTerm);
   }
  getControl(control: string): FormControl {
    return this.ProductForm.get(control) as FormControl;
  }

  onSubmit() {   
    if(this.isDraftProduct){
      this.saveDraftProduct();
    }else{
      this.saveProduct();
    }
  }
  saveDraftProduct(){
    let trimmedProductName:string = this.Product.value??"";
    trimmedProductName =(trimmedProductName.trimStart()).trimEnd();
     let draftProductModel:CreateDraftProductModel={
       name: trimmedProductName,
       description: 'This is a draft product.',
       businessAreaCode:this.businessArea,
       cmdbId: this.cmdbId,
       apimProductId: this.ProductForm.value.ProductId,
       requireSubscription: true,
       isPublished: false,
       clientAppRegistrationID: '',
       requiresManualApproval: false
     }
     this.loaderActive=true;
     this.hipManagementService.saveDraftProduct(draftProductModel).subscribe({
      next:(response:any) => {
        this.loaderActive=false;
        this.successMsg= this.Product.value + ' updated successfully';
        this.showSuccess=true;             
        this.feedbackActive =true; 
        this.successFull = true; 
        this.failure = false; 
        timer(5000).subscribe(x => this.showSuccess = false);       
      },
      error:(error:any) => {    
        this.loaderActive=false;  
        let errMsg='';                 
        if(error.error.message){
          errMsg=error.error.message;
        }
        else if(error.error){
          errMsg=error.error;
        }
        this.failureMsg = this.Product.value + ' update failed with exception: ' + errMsg;
        this.showFailure = true;  
        this.feedbackActive =true;
        this.successFull = false;
        this.failure = true;  
        timer(5000).subscribe(x => this.showFailure = false);       
      }
    });
}

  saveProduct() {    
    var approversList:any[]=[];
    var apisList:any[]=[];
    var productAppRegOwnerList:any[]=[];
    if(this.approverRequired=='yes'){
      this.ProductForm.value.Approvers.forEach((element: { id: any; }) => {
        approversList.push(element.id);
       });
    }
    this.ProductForm.value?.APIs?.forEach((element: { id: any; }) => {
     apisList.push({ "apimApiId":element.id});
    });      
      this.ProductForm.value.AppRegistrationOwners?.forEach((element: { id: any; }) => {
        productAppRegOwnerList.push(element.id)
      });
    let trimmedProductName:string = this.ProductForm.value.Product;
    trimmedProductName =(trimmedProductName.trimStart()).trimEnd();
    this.createProduct = {
        cmdbId:this.cmdbId,
        businessAreaCode:this.businessArea,
        productApprovers:approversList,
        description:this.ProductForm.value.Description,
        apiList:apisList,        
        clientAppRegistrationID:'',
        isPublished:this.ProductForm.value.RadioWrapper.Published.toLowerCase() == "yes" ? true : false,
        requiresManualApproval: this.ProductForm.value.RadioWrapper.RequiredApproval.toLowerCase() == "yes" ? true : false,
        requireSubscription: true,
        apimProductId:this.ProductForm.value.ProductId,
        name:trimmedProductName,
        appRegistrationOwners:productAppRegOwnerList
    }
    this.loaderActive=true;
    this.hipManagementService.editProduct(this.createProduct).subscribe({
      next:(response:any) => {
        this.loaderActive=false;
        this.successMsg= this.Product.value + ' updated successfully';
        this.showSuccess=true;             
        this.feedbackActive =true; 
        this.successFull = true; 
        this.failure = false; 
        timer(5000).subscribe(x => this.showSuccess = false);       
      },
      error:(error:any) => {    
        this.loaderActive=false;  
        let errMsg='';                 
        if(error.error.message){
          errMsg=error.error.message;
        }
        else if(error.error){
          errMsg=error.error;
        }
        this.failureMsg = this.Product.value + ' update failed with exception: ' + errMsg;
        this.showFailure = true;  
        this.feedbackActive =true;
        this.successFull = false;
        this.failure = true;  
        timer(5000).subscribe(x => this.showFailure = false);       
      }
    });  
  }
  onClick(pageName:string):void{
    this.router.navigate([`${pageName}`]);
  }
  preventSubmit(event: any){      
    event.preventDefault();
  }

@HostListener('window: click', ['$event'])
onWindowClick(event: any) { 
  if(this.AppNameAndNumber.value){
  }
}

@HostListener('window: keydown', ['$event'])
onWindowKeyDown(event: any){
  if(this.feedbackActive){
    if (event.keyCode == 9) {
        event.preventDefault();
    }
  }
}

ngOnDestroy(): void {
  this.subscriptions.forEach((subscription) => subscription.unsubscribe());
}

triggerNext(isCurrentTabValid:boolean, fromTab:string, toTab:string){
  if(this.isDraftProduct && isCurrentTabValid){
    this.resetScroll();
    var element = Array.from(document.getElementsByName('review'));
    element[0].click(); 
  }else{if(fromTab=="basic"){
this.getProductAppRegName();
  }
  if(isCurrentTabValid){
    this.resetScroll();
    var element = Array.from(document.getElementsByName(toTab));
    element[0].click();
  }
}
}

getProductAppRegName(){
let envValue=Environment.environment;
this.productAppRegNameValue = ((this.applicationId).toLocaleLowerCase())+"-"+this.ProductId.value+"_product_hip-"+envValue;
this.productAppRegName.setValue(this.productAppRegNameValue)
}
triggerBackStep(toTab:string){
  if(toTab=="basic"){
    this.isManagementTabActivated=false;
  }
  this.resetScroll();
  var element = Array.from(document.getElementsByName(toTab));
  element[0].click();
}
resetScroll(){
const BodyElement: HTMLElement | null = document.getElementById('bodyContent');
if(BodyElement != null){
   BodyElement.scrollTop = 0;
  }
}
onDraftProductToggle(event:any){
  this.isDraftProduct=!this.isDraftProduct;
  if(this.isDraftProduct){
    this.draftProductOption.push({label:this.draftProductLabel});
    this.description.setValue('This is a draft product.');
    this.published.setValue('No');
    this.setApproverMandate();
  }else{
    this.draftProductOption.pop();
    this.description.setValue('');
  }
}
onApiChanges(){
  this.APIs.valueChanges.pipe(debounceTime(1200)).subscribe((x)=>{
    this.validateSubAttributeApi();
  })
}
validateSubAttributeApi(){
  let apiList:any[]=[];
  this.APIs.value?.forEach((api:any)=>{
    let apiValue={
      'apimApiId':api.id
    }
    apiList.push(apiValue);
  })
  let validationReq={
    apiList: apiList
  }
  this.hipManagementService.getValidateSubscriptionAttributesForAPIs(validationReq).subscribe(
    {
      next:(response) =>{
        if(response==true){
          this.requiredApproval.setValue('Yes');
          this.setApproverMandate();
          this.isApproverMandateOnly=true;
        }else{
          this.isApproverMandateOnly=false;
        }
      }
    }
  )
}
}
