import { Component, ElementRef, HostListener, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { ManageProductList } from '../models/manage-product-list';

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.scss']
})
export class AllProductsComponent {
  pageNo: number = 1;
  itemsPerPage : number = 12;
  totalRecordCount:number =0;  
  selectedBusinessValue: string[] = [];
  productTagArray: {productId:string, tags: string[]}[] = [];
  gridArray: {
    "uniqueId":string,
    "name":string,
    "description":string,
    "businessArea":string,
    "apimProductName":string
  }[]=[];
  loaderEnabled:boolean = false;
  productName: string = '';
  searchProductValue: string ='';  
  FilterForm: FormGroup = new FormGroup({});
  BusinessFilter: FormControl = new FormControl('');
  searchProductValueChanged:Subject<string> = new Subject<string>();
  searchProductSubscription!: Subscription;
  pendingChildAPICall:number = 0;
  productApis:{productId:string,apiList:{"apimApiName":string, "name":string}[]}[] =[];

  @ViewChildren('contentSection') contentSections!: ElementRef[];
  @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];

  businessAreaList: {"id":string, "label":string}[] = [  
  {
    'id': '1',
    'label': 'CT'
  },
  {
    'id': '2',
    'label': 'CO'
  },
  {
    'id': '3',
    'label': 'IT'
  },
  {
    'id': '4',
    'label': 'PT'
  },
  {
    'id': '5',
    'label': 'VT'
  }]

  constructor(private hipManagementService: Hip2ManagementService, private router: Router) { 
    let search = this.router.getCurrentNavigation()?.extras?.state?.['search'];
    if(search!=undefined){
      this.productName=search;
        this.searchProductValue=search;
        console.log(this.searchProductValue);
    }
  }
  ngOnInit(): void {
    this.FilterForm = new FormGroup({
      BusinessFilter: this.BusinessFilter
    });
    this.loadProducts();
    this.controlDataChange();   
  }

  ngAfterContentInit(): void {
    this.searchProductSubscription = this.searchProductValueChanged.pipe(debounceTime(1500)).subscribe(value=>{
      this.searchProductValue=value;
      if(this.searchProductValue.length>2 || this.searchProductValue.length==0){
        this.pageNo=1;
        this.loadProducts();    
        this.setContentSectionHeight();
      }     
    })
    this.setContentSectionHeight();
  }

  // listen for window resize events
@HostListener('window:resize')
onResize() {
  this.setContentSectionHeight();
}
  setContentSectionHeight() {
    const windowHeight = window.innerHeight;
    let bodyWrapperHeight: any;
    this.bodyWrapper?.forEach(section =>{
      bodyWrapperHeight = windowHeight - section.nativeElement.offsetTop;
    });
      this.contentSections?.forEach(section => {
        const offsetTop = section.nativeElement.offsetTop;
        console.log('window-height', bodyWrapperHeight, 'offset-Top', offsetTop);
        
        const sectionHeight = bodyWrapperHeight - offsetTop - (2.5 * 48);
        section.nativeElement.style.minHeight = `${sectionHeight}px`;
      });
  }
  
  loadProducts() {
    let businessAreaCode = '';
    this.selectedBusinessValue.forEach(element => {
      businessAreaCode=businessAreaCode.length==0?element:(businessAreaCode+','+element)
    });
    let filter = this.searchProductValue;
    let pageIndex =  this.pageNo;
    let pageSize = this.itemsPerPage;  
    let apiVersion = '1.0';
    let sortDirection = 'asc';
    this.loaderEnabled=true;
    this.resetScroll();
    this.productTagArray=[];
    this.productApis=[];
    this.hipManagementService.getAllProducts(businessAreaCode, filter, sortDirection,
      pageIndex, pageSize, apiVersion).subscribe
      ({
          next: (productsAndApi: ManageProductList) => {
            this.gridArray=[];
            productsAndApi.productData.forEach(productDetail => {
              let tagList :string[]= [];
              productDetail.apiTagList.forEach(apiTag => {
                apiTag.tags.forEach(tag => {
                  if(!tagList.includes(tag)){
                      tagList.push(tag);
                  }
                });
              });
                let product = {
                  "uniqueId":productDetail.id,
                  "name":productDetail.displayName,
                  "description":productDetail.description,
                  "businessArea":productDetail.businessAreaCode,
                  "apimProductName":productDetail.apimProductName,
                  "info": "/developer/view-product/"+productDetail.apimProductName,
                  "tags":tagList,
                  "apiTagList":productDetail.apiTagList
                }
                this.gridArray.push(product);
            });           
            this.totalRecordCount = productsAndApi.totalCount;
            this.loaderEnabled = false;
          },
          error: (errorDetail: string) => {
      this.loaderEnabled = false;
          }          
        });
       
  }

  controlDataChange(){    
    this.BusinessFilter.valueChanges.subscribe((x) => {
      if(x != null) {
      let businessValue = x.map((obj:any)=>{return obj.label});
      this.selectedBusinessValue = businessValue;
      this.pageNo = 1;
      this.productTagArray=[];
      this.productApis=[];
      this.loadProducts();
      }
    });   
  }

  onTablePage(event: any){
    this.pageNo = event;
    this.loadProducts();
  }
  public renderPageValue(value: number) {
    this.itemsPerPage = value;
    this.pageNo = 1;
    this.loadProducts();
   }

   searchProduct(productValue: string){
    this.searchProductValueChanged.next(productValue);
  }

  onTagClick(searchTag: any){
    this.productName=searchTag;
    this.searchProductValueChanged.next(searchTag);
    this.loaderEnabled=true;
    this.resetScroll()
  }
  resetScroll(){
         const bodyElement: HTMLElement | null = document.getElementById('bodyContent');
         if(bodyElement != null){
            bodyElement.scrollTop=0;
         }
  }
 ngOnDestroy(){
    this.searchProductSubscription.unsubscribe();
  }

  clearSearch(){
    this.productName="";
    this.searchProductValueChanged.next("");
  }
}

