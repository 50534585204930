<div #bodyWrapper>
    <app-bread-crumb [links]="[
    {
      'href': '/publisher/manage-apis',
      'title': 'Manage APIs', 
      'description': 'An overview of your APIs as a publisher. '
    }
  ]" [buttonProps]=""></app-bread-crumb>
    <section class="content-section container"  #contentSection>
      <!-- <app-table></app-table>
      <app-accordion-table></app-accordion-table> -->
      <div class="ds_c-listing-table">
        <div class="ds_c-listing-table-filter col-sm-12">
          <div class="navbar col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
            <span class="col-12 col-sm-12 col-md-4" style="position: relative">
              <input
                #searchApiField
                type="text"
                class="text-field-responsive form-control"
                name="searchApivalue"
                [(ngModel)]="searchApivalue"
                (ngModelChange)="apiSearch($event)"
                placeholder="Search with at least 3 characters"
                (keydown.enter)="searchApiField.blur()"
              />
              <span class="search-icon-placement" *ngIf="searchApiField.value.length==0"></span>
              <span *ngIf="searchApiField.value.length > 0" class="removeBtn-search" (click)="clearSearch()" ></span>
            </span>
            <span [formGroup]="filterForm" class="col-12 col-sm-12 col-md-7 filter-placement">
                <app-multi-select-dropdown class="multi-select" [props]="{placeholder: 'Filter on business area'}" [control]="businessFilter" [dataSets]="businessAreaList" [isSearchEnabled]="isSearchBusinessFilterEnabled"></app-multi-select-dropdown>
            </span>
            
          
          </div>
          <span class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 pageControls">
            <span class="ds_c-renderCount">
              <span>{{totalRecordCount === 0? '00':(((this.pageNo-1) * (itemsPerPage) + 1) === 1) ? '01' : ((this.pageNo-1) * (itemsPerPage) + 1)}} - {{((itemsPerPage) * (this.pageNo) > (this.totalRecordCount)) ? this.totalRecordCount : (itemsPerPage) * (this.pageNo)}} of {{this.totalRecordCount}}</span>
              <app-select-drpdwn [Options]="[10, 20, 30]" [perPageRender]="this.itemsPerPage" (perPageValue)="renderPageValue($event)"></app-select-drpdwn>
            </span>
            <pagination-controls class=" ds_c-pagination" (pageChange)="onTablePage($event)"  [maxSize]="5"  (responsive)="true"  previousLabel=""
            nextLabel=""></pagination-controls>
          </span>
        </div>
        <div style="overflow-x: auto;">
          <app-shimmer-table *ngIf="this.loaderEnabled == true"></app-shimmer-table>
          <app-ds-table *ngIf="this.loaderEnabled == false" [HeadArray]="apiTableHeaderList" [GridArray]="gridArray" [DataKeys]="dataKeys" [itemsPerPage]="itemsPerPage" [pageNo]="pageNo" [sorting]="sortingHeaderList" [tableInfo]="tableInfo" [totalRecords]="totalRecordCount"></app-ds-table>
        </div>

      </div>
      <span class="col-12 footer-pageControls">
        <span class="ds_c-renderCount">
          <span>{{totalRecordCount === 0? '00':(((this.pageNo-1) * (itemsPerPage) + 1) === 1) ? '01' : ((this.pageNo-1) * (itemsPerPage) + 1)}} - {{((itemsPerPage) * (this.pageNo) > (this.totalRecordCount)) ? this.totalRecordCount : (itemsPerPage) * (this.pageNo)}} of {{this.totalRecordCount}}</span>
          <app-select-drpdwn [Options]="[10, 20, 30]" [perPageRender]="this.itemsPerPage" (perPageValue)="renderPageValue($event)"></app-select-drpdwn>
        </span>
        <pagination-controls class=" ds_c-pagination" (pageChange)="onTablePage($event)"  [maxSize]="5"  (responsive)="true"  previousLabel=""
        nextLabel=""></pagination-controls>
      </span>
    </section>
    </div>