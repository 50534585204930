import { debounceTime, from, Subject, Subscription,timer } from 'rxjs';
import { AfterContentInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { StateService } from 'src/app/shared/services/state.service';
import { Api } from '../../apis/models/api';
import { CreateDraftProductModel, CreateProductModel } from '../../models/product-model';
import {Environment} from '../../../../environments/environment'

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit, AfterContentInit, OnDestroy {
  createProduct!: CreateProductModel;  
  applicationId:string='';
  subscriptions: Subscription[] = [];
  approverRequired:string = '';
  businessArea:string = "";
  appRegDataSet:any[] = [];
  approversDataSet:any[] = [];
  appRegistrationOwnersDataSet:any[]=[];
  apisDataSet:any[]=[];
  defaultApisDataSet: any[]=[];
  applicationDataSet:any[]=[];
  informationData:any[]=[];
  checkError: boolean = false;
  showSuccess:boolean = false;
  showFailure:boolean = false;
  successFull:boolean = false;
  failure:boolean = false;
  successMsg:string='';
  failureMsg:string='';
  feedbackActive: boolean = false;
  loaderActive: boolean = false;
  disableApprover:boolean = false;  
  isCustomOptionForApproverRequired:boolean=true;
  isCustomOptionForAppRegOwnersRequired:boolean=true;
  ProductForm : FormGroup = new FormGroup({});
  basicTabProductForm: FormGroup = new FormGroup({});
  managementTabProductForm: FormGroup = new FormGroup({});
  published:FormControl= new FormControl('');
  requiredSubscription:FormControl= new FormControl('');
  requiredApproval:FormControl= new FormControl('');
  RadioWrapper: FormGroup = new FormGroup({
    Published: this.published,
        RequiredSubsc: this.requiredSubscription,
        RequiredApproval: this.requiredApproval
  });  
  
  AppNameAndNumber : FormControl = new FormControl('', [Validators.required, Validators.minLength(3)]);
  Product = new  FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(59)]);
  APIs = new FormControl([], [Validators.required, Validators.minLength(1)]);
  ProdAppName= new FormControl('', [Validators.required, Validators.minLength(2)]);
  ProductId = new FormControl('', [Validators.maxLength(84)]);
  AppOwner = new FormControl('', null);  
  AppNumber = new FormControl('');
  Approvers = new FormControl([]);
  appRegOwners = new FormControl([],[Validators.required])
  description = new FormControl('', [Validators.required]);
  productAppRegName = new FormControl('');
  applicationSubject: Subject<string> = new Subject();
  apiSubject: Subject<string> = new Subject();
  appRegSubject: Subject<string> = new Subject();
  userSubject: Subject<string> = new Subject();
  appRegistrationOwnerSubject: Subject<string> = new Subject();
  pageInfo:string="A Product is a collection of one or more APIs. Approvers can be assigned to keep control over any new Product subscriptions. Once approved, a subscribing application gets access to all APIs inside a Product.\n\n You are about to create a new Product in Azure API Management.";
  productAppRegNameValue:string = '';
  isBasicTabActivated:boolean = false;
  isManagementTabActivated:boolean = false;
  isReviewTabActivated:boolean = false;
  isSubmitted:boolean = false;
  draftProductLabel:string='Draft Product';
  isCustomLabelEnabled:boolean=true;
  draftProductInfo:string='Create only a draft version of the product, with a minimum of information, not visible for subscribers';
  isDraftProduct:boolean=false;
  draftProductOption:any[]=[];
  productAppRegistrationTooltip = 'We would like to remind you of the available documentation about "<a href="https://ac-its.atlassian.net/wiki/spaces/AHIP/pages/22216724/How+to+create+an+Application+Registration+for+a+Product+Subscription" target="_blank" style="font-size:inherit">How to create/configure an App Registration?</a>"'
  isApproverMandateOnly:boolean=false;
  constructor(private hipManagementService: Hip2ManagementService,private router:Router,
    private stateservice: StateService){}
  
  ngOnInit(): void {
    this.ProductForm = new FormGroup({
      Product: this.Product,
      ProductId: this.ProductId,
      AppNameAndNumber: this.AppNameAndNumber,
      displayAppNameAndNumber: this.AppNumber,
      AppOwner: this.AppOwner,
      Description: this.description ,
      APIs: this.APIs,            
      Approvers: this.Approvers,
      AppRegistrationOwners: this.appRegOwners,      
      RadioWrapper: this.RadioWrapper      
    });
    this.setFormValue();
  this.basicTabProductForm = new FormGroup({
    Product: this.Product,
    ProductId: this.ProductId,
    AppNameAndNumber: this.AppNameAndNumber,
    displayAppNameAndNumber: this.AppNumber,
    Description: this.description,
    APIs: this.APIs
  });
  this.managementTabProductForm = new FormGroup({
    AppRegistrationOwners: this.appRegOwners      
  })
  this.onApiChanges();
  }

  setFormValue(){
    this.setApproverMandate();
    this.ProductForm.patchValue({      
      RadioWrapper: {
        "Published": "No",
        "RequiredSubsc": "Yes",
        "RequiredApproval": "Yes"
      }      
    })    
   }

  getCmdbApplications(searchText: string){             
        this.hipManagementService.getCmdbApplications(searchText).subscribe({          
            next:(response:any)=>{              
              if(response.length > 0) {
                this.applicationDataBind(response);
              }
              else {                
                this.applicationDataSet=[];
              }              
            },
            error:(error:any)=> {              
              this.applicationDataSet = [];
              this.resetApplicationInfo();
              this.ProductForm.patchValue({
                AppNameAndNumber:""
              });
            }
        });        
  }

  resetApplicationInfo(){
    this.AppOwner.setValue('');
    this.ProductId.setValue('');          
    this.businessArea='';    
    this.informationData=[]; 
  }

  getCmdbDetails(cmdbId:string){
    this.loaderActive = true;
    this.hipManagementService.getCmdbDetails(cmdbId).subscribe({ 
      next:(response:any)=>{
        this.loaderActive = false;
        if (response !== null){
          this.AppOwner.setValue(response.applicationOwner);
          this.businessArea = response.businessArea;        
          this.populateProductId();          
          this.informationData = [
            {'title':'Application Name', 'value':this.AppNameAndNumber.value.value },
            {'title':'Application Number', 'value':this.AppNameAndNumber.value.id },          
            {'title':'Business Area', 'value': response.businessArea },
            {'title':'Unit Code', 'value': response.familyCode },
            {'title':'Cost Center', 'value': response.costCenter }
          ];               
        }      
      },
      error:(error:any)=>{
        this.loaderActive = false;        
      }           
    });
  }

  applicationDataBind(data:any){
    this.applicationDataSet=[];
    data.forEach((element: { applicationId: any; applicationName: any; }) => {
      this.applicationDataSet.push({
        'id':element.applicationId,
        'value':element.applicationName                
      })
    })
  } 
  
  apisDataBind(data:any){
    this.apisDataSet=[];
    this.defaultApisDataSet = [];
    data.forEach((element: { displayName: any; apimApiId: any; }) => {
      this.apisDataSet.push({
        'id':element.apimApiId,
        'label':element.displayName
      })
    })
    this.defaultApisDataSet=this.apisDataSet;    
  }

  getAdApplications(appName: string):any {
    this.hipManagementService.getAdApplications(appName,this.applicationId).subscribe({
        next:(response:any)=> {
          if(response.length > 0){
            this.appRegistrationDataBind(response);          
          }
          else {
            this.appRegDataSet =[];
          }          
        },
        error:(error:any)=>{          
          this.appRegDataSet =[];
        }  
    });
  }

  appRegistrationDataBind(data:any){   
    this.appRegDataSet=[];
    data.forEach((element: { clientAppName: any; appId: any; }) => {      
      this.appRegDataSet.push({                      
        'id': element.appId,
        'value': element.clientAppName                
      });  
    });
  }

  getAdUsers(searchText: string):any {    
    this.hipManagementService.getListOfADUsers_Approvers(searchText).subscribe({  
      next:(response:any)=>{
        if(response.length > 0) {
          this.approversDataBind(response);              
        }
        else {          
          this.approversDataSet =[];
        }        
      },
      error:(error:any)=>{      
        this.approversDataSet =[];
      }     
    });
  }

  getAppRegistrationOwners(searchText: string):any {    
    this.hipManagementService.getListOfADUsersOwners(searchText).subscribe({  
      next:(response:any)=>{
        if(response.length > 0) {
          this.appRegistrationOwnersDataBind(response);              
        }
        else {          
          this.appRegistrationOwnersDataSet =[];
        }        
      },
      error:(error:any)=>{      
        this.appRegistrationOwnersDataSet =[];
      }     
    });
  }

  appRegistrationOwnersDataBind(data:any) {
    this.appRegistrationOwnersDataSet=[];
    data.forEach((element: { email: any; name: any; }) => {      
      this.appRegistrationOwnersDataSet.push({                      
        'id': element.email,
        'label': element.name ,
        'customLabel': element.name + ' ['+element.email+']'                
      });  
    });
  }

  approversDataBind(data:any) {
    this.approversDataSet=[];
    data.forEach((element: { email: any; name: any; }) => {      
      this.approversDataSet.push({                      
        'id': element.email,
        'label': element.name ,
        'customLabel': element.name + ' ['+element.email+']'                
      });  
    });
  }

  enableApproval(event: any){
  if(event) {
    if(event.toLowerCase() == 'yes'){      
      this.disableApprover = false;
      this.setApproverMandate();
    }else if(event.toLowerCase() == 'no'){      
      this.disableApprover = true;
      this.removeApproverMandate();
    }else{
      this.disableApprover = false;           
      this.setApproverMandate();
    }    
  }
}

  setApproverMandate(){
    this.approverRequired = 'yes';
    this.Approvers.setValidators([Validators.required]);
    this.Approvers.updateValueAndValidity();
  }
  removeApproverMandate(){    
    this.Approvers.clearValidators();
    this.Approvers.updateValueAndValidity(); 
    this.approverRequired = 'no';
  }
  
  populateProductId(){        
    if (this.businessArea != "") {
      let generatePrdId:string;
      if(this.Product.value != undefined){
        let trimmedProductName:string = this.Product.value; 
        trimmedProductName=trimmedProductName.replace(/[^a-zA-Z0-9]/g, ' '); 
      if (trimmedProductName != undefined && trimmedProductName != '') {
        trimmedProductName = (trimmedProductName.trimStart()).trimEnd();
        trimmedProductName=trimmedProductName.split(' ').filter(name => name).join(' ');
        generatePrdId = this.businessArea?.toLowerCase()+"-"+trimmedProductName?.toLowerCase().replace(/ /g, "-") +"-"+this.applicationId?.split("-")[1];
      }
      else {
        generatePrdId = this.businessArea?.toLowerCase()+"-"+this.applicationId?.split("-")[1];
      }    
      this.ProductId.setValue(generatePrdId);
      this.ProductForm.patchValue({
        ProductId: generatePrdId
      })
    }   
    }        
  }

  appSelected(event: any){    
    this.resetApplicationInfo();
    console.log(JSON.stringify(event));
    if (event) {
        this.applicationId = event.id;        
        this.getCmdbDetails(this.applicationId);  
        this.ProductForm.controls['APIs'].reset(); 
        this.loadApis(this.applicationId);
        this.getAdApplications("");
    }
    else{
      this.applicationId='no_data';
      this.apisDataSet=[];
      this.defaultApisDataSet = [];
    }
   }

   applicationSearch(searchTerm: string){     
      this.applicationSubject.next(searchTerm);       
   }

   ngAfterContentInit() : void {
    this.subscriptions.push(this.applicationSubject.pipe(debounceTime(1500)).subscribe(value =>{            
      if (value.length > 2) {
        this.getCmdbApplications(value);
      }
      else{
        this.applicationDataSet=[];
        this.appRegDataSet=[];
        this.resetApplicationInfo();
      }          
    }));           

    this.subscriptions.push(this.userSubject.pipe(debounceTime(1500)).subscribe(value =>{      
      if (value.length > 2) {
        this.getAdUsers(value);          
      }
      else {
        this.approversDataSet = [];
      }      
    }));     

    this.subscriptions.push(this.appRegistrationOwnerSubject.pipe(debounceTime(1500)).subscribe(value =>{
      if(value.length > 2){
          this.getAppRegistrationOwners(value);
      } else{
        this.appRegistrationOwnersDataSet = [];
      }
    }))
   }



   apiSearch(searchTerm: string){             
    if(searchTerm.length) {
      this.apisDataSet = [];
      for (let i = 0; i < this.defaultApisDataSet.length; i++){            
        if(this.defaultApisDataSet[i].label?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          this.apisDataSet.push(this.defaultApisDataSet[i]);                  
        }
      }
    }
    else {
      this.apisDataSet = this.defaultApisDataSet;
    }
   }

  loadApis(appId: string){   
    let cmdbId= ""; 
    if (appId != null && appId != "") {
       cmdbId = appId;
    } 
    this.loaderActive=true;
    this.hipManagementService.getAllApimApis(cmdbId).subscribe(
        {          
          next: (api:any) => {                        
            this.loaderActive = false;
            if(api.length > 0) {
              this.apisDataBind(api);
            }
            else{              
              this.apisDataSet = [];
              this.defaultApisDataSet=[];
            }            
          },
          error: (err: string) => {            
            this.loaderActive = false;
            this.apisDataSet = [];
            this.defaultApisDataSet=[];
          }
        });

   }

   userSearch(searchTerm: string){        
    this.userSubject.next(searchTerm);      
   }
  
   appRegistrationOwnerSearch(searchTerm: string){
     this.appRegistrationOwnerSubject.next(searchTerm);
   }
  getControl(control: string): FormControl {
    return this.ProductForm.get(control) as FormControl;
  }

  onSubmit() {   
    if(this.isDraftProduct){
      this.addDraftProduct();
    }else{
      this.addProduct();
    }
  }
  addDraftProduct(){
    let trimmedProductName:string = this.ProductForm.value.Product;
    trimmedProductName =(trimmedProductName.trimStart()).trimEnd();
     let draftProductModel:CreateDraftProductModel={
       name: trimmedProductName,
       description: 'This is a draft product.',
       businessAreaCode:this.businessArea,
       cmdbId: this.AppNameAndNumber.value.id,
       apimProductId: this.ProductForm.value.ProductId,
       requireSubscription: true,
       isPublished: false,
       clientAppRegistrationID: '',
       requiresManualApproval: false
     }
     this.loaderActive=true;
     this.hipManagementService.addDraftProduct(draftProductModel).subscribe({
      next:(response:any) => {
        this.loaderActive=false;
        this.successMsg= 'Product '+this.Product.value +' created successfully.';
        this.showSuccess=true;             
        this.feedbackActive =true; 
        this.successFull = true; 
        this.failure = false; 
        timer(5000).subscribe(x => this.showSuccess = false);       
      },
      error:(error:any) => {    
        this.loaderActive=false;  
        let errMsg='';                 
        if(error.error.message){
          errMsg=error.error.message;
        }
        else if(error.error){
          errMsg=error.error;
        }
        this.failureMsg = this.Product.value + ' creation failed with exception: ' + errMsg;
        this.showFailure = true;  
        this.feedbackActive =true;
        this.successFull = false;
        this.failure = true;  
        timer(5000).subscribe(x => this.showFailure = false);       
      }
    });
}

  addProduct() {    
    var approversList:any[]=[];
    var apisList:any[]=[];
    var productAppRegOwnerList:any[]=[];
    if(this.approverRequired=='yes'){
      this.ProductForm.value.Approvers.forEach((element: { id: any; }) => {
        approversList.push(element.id);
       });
    }
    this.ProductForm.value.APIs?.forEach((element: { id: any; }) => {
     apisList.push({ "apimApiId":element.id});
    });      
      this.ProductForm.value.AppRegistrationOwners?.forEach((element: { id: any; }) => {
        productAppRegOwnerList.push(element.id)
      });
    let trimmedProductName:string = this.ProductForm.value.Product;
    trimmedProductName =(trimmedProductName.trimStart()).trimEnd();
    this.createProduct = {
        cmdbId:this.AppNameAndNumber.value.id,
        businessAreaCode:this.businessArea,
        productApprovers:approversList,
        description:this.ProductForm.value.Description,
        apiList:apisList,        
        clientAppRegistrationID:'',
        isPublished:this.ProductForm.value.RadioWrapper.Published.toLowerCase() == "yes" ? true : false,
        requiresManualApproval: this.ProductForm.value.RadioWrapper.RequiredApproval.toLowerCase() == "yes" ? true : false,
        requireSubscription: true,
        apimProductId:this.ProductForm.value.ProductId,
        name:trimmedProductName,
        appRegistrationOwners:productAppRegOwnerList
    }
    this.loaderActive=true;
    this.hipManagementService.addProduct(this.createProduct).subscribe({
      next:(response:any) => {
        this.loaderActive=false;
        this.successMsg= 'Product '+this.Product.value +' created successfully.';
        this.showSuccess=true;             
        this.feedbackActive =true; 
        this.successFull = true; 
        this.failure = false; 
        timer(5000).subscribe(x => this.showSuccess = false);       
      },
      error:(error:any) => {    
        this.loaderActive=false;  
        let errMsg='';                 
        if(error.error.message){
          errMsg=error.error.message;
        }
        else if(error.error){
          errMsg=error.error;
        }
        this.failureMsg = this.Product.value + ' creation failed with exception: ' + errMsg;
        this.showFailure = true;  
        this.feedbackActive =true;
        this.successFull = false;
        this.failure = true;  
        timer(5000).subscribe(x => this.showFailure = false);       
      }
    });  
  }
  onClick(pageName:string):void{
    this.router.navigate([`${pageName}`]);
  }
  clearAllData(){
    this.ProductForm.reset();
    this.setFormValue();
    this.resetApplicationInfo();
  }
  preventSubmit(event: any){      
    event.preventDefault();
  }

@HostListener('window: click', ['$event'])
onWindowClick(event: any) { 
  if(this.AppNameAndNumber.value){
  }
}

@HostListener('window: keydown', ['$event'])
onWindowKeyDown(event: any){
  if(this.feedbackActive){
    if (event.keyCode == 9) {
        event.preventDefault();
    }
  }
}

ngOnDestroy(): void {
  this.subscriptions.forEach((subscription) => subscription.unsubscribe());
}

triggerNext(isCurrentTabValid:boolean, fromTab:string, toTab:string){
  if(this.isDraftProduct && isCurrentTabValid){
    this.resetScroll();
    var element = Array.from(document.getElementsByName('review'));
    element[0].click(); 
  }else{if(fromTab=="basic"){
this.getProductAppRegName();
  }
  if(isCurrentTabValid){
    this.resetScroll();
    var element = Array.from(document.getElementsByName(toTab));
    element[0].click();
  }
  this.validateSubAttributeApi();
}
}

getProductAppRegName(){
let envValue=Environment.environment;
this.productAppRegNameValue = ((this.applicationId).toLocaleLowerCase())+"-"+this.ProductId.value+"_product_hip-"+envValue;
this.productAppRegName.setValue(this.productAppRegNameValue)
}
triggerBackStep(toTab:string){
  if(toTab=="basic"){
    this.isManagementTabActivated=false;
  }
  this.resetScroll();
  var element = Array.from(document.getElementsByName(toTab));
  element[0].click();
}
resetScroll(){
const BodyElement: HTMLElement | null = document.getElementById('bodyContent');
if(BodyElement != null){
   BodyElement.scrollTop = 0;
  }
}
onDraftProductToggle(event:any){
  this.isDraftProduct=!this.isDraftProduct;
  if(this.isDraftProduct){
    this.draftProductOption.push({label:this.draftProductLabel});
    this.description.setValue('This is a draft product.');
    this.published.setValue('No');
    this.requiredApproval.setValue('No');
    this.approverRequired = 'no';
    this.Approvers.clearValidators();
    this.Approvers.updateValueAndValidity();
  }else{
    this.draftProductOption.pop();
    this.description.setValue('');
    this.requiredApproval.setValue('Yes');
    this.setApproverMandate();
  }
}
onApiChanges(){
  this.APIs.valueChanges.pipe(debounceTime(1200)).subscribe((x)=>{
    this.validateSubAttributeApi();
  })
}
validateSubAttributeApi(){
  let apiList:any[]=[];
  this.APIs.value?.forEach((element:any) => {
    let api={"apimApiId":element.id};
    apiList.push(api);
  });
  let validationReq={
    apiList: apiList
  }
  this.hipManagementService.getValidateSubscriptionAttributesForAPIs(validationReq).subscribe(
    {
      next:(response) =>{
        if(response==true){
          this.requiredApproval.setValue('Yes');
          this.setApproverMandate();
          this.isApproverMandateOnly=true;
        }else{
          this.isApproverMandateOnly=false;
        }
      }
    }
  )
}
}
