<div #bodyWrapper>
    <app-bread-crumb [links]="[
    {
      'href': '/publisher/manage-products',
      'title': 'Manage Products'
    },
    {
      'href': 'publisher/manage-products/view-product/'+this.breadCrumbProductId,
      'title': ''+this.breadCrumbProductName
    },
    {
        'href':  'publisher/manage-products/view-product/'+this.breadCrumbProductId,   
        'title': 'Product Details'               
    }
  ]" [buttonProps]="buttonProps" ></app-bread-crumb>
      <section class="content-section" >
    <app-general-loader *ngIf="loaderActive"></app-general-loader>
    <div class="form-section" [class.loading]="loaderActive">
        <div class="container form-head" [class.loading]="loaderActive">
            <h2 class="title">Product Details</h2>
            <p>Please find a preview and detailed overview of all product related information below.</p>

        </div>
        <div class="container cardWrapper" [class.loading]="loaderActive">
            <app-product-card [dataset]="this.dataset" [showSingleCardStyle]=true [isClickableLabelReq]=false></app-product-card>
        </div>
        <div class="container" id="view" [class.loading]="loaderActive">
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title">Product Name</h3>
                    <h3 class="bold title">{{this.dataset.productName}}</h3>
                </div>
                <div class="col-12 col-sm">
                    <h3 class="title">Product Id</h3>
                    <h3 class="bold title">{{this.dataset.productID}}</h3>
                </div>
                <div class="col-12 col-sm-12 col-md-5" *ngIf="!isDraftProduct">
                    <h3 class="title">Product App Registration Name</h3>
                    <h3 class="title bold" style="display: flex; word-break: break-all;">{{this.dataset.registration}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/{{this.dataset.appRegistrationId}}/isMSAApp~/false" target="_blank" style="display: flex;"><img src="../../../../assets/_target.svg" alt="" class="img-svg-wrapper"></a></h3>
                </div>
            </div>
            <div class="row" *ngIf="isDraftProduct">
            <div class="col-12 col-sm">
                <h3 class="title">Draft Product</h3>
                <h3 class="title bold">{{this.isDraftProduct==true?'Yes':'No'}}</h3>
            </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm description-overflow">
                    <h3 class="title">Description</h3>
                    <h3 class="title bold">{{this.dataset.description}}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title">Published</h3>
                    <h3 class="title bold">{{this.dataset.published}}</h3>
                </div>
                <div class="col-12 col-sm">
                    <h3 class="title">Require Subscription</h3>
                    <h3 class="title bold">{{this.dataset.subscriptionRequired}}</h3>
                </div>  
                <div class="col-12 col-sm-12 col-md-5">
                    <h3 class="title">Require Approval</h3>
                    <h3 class="title bold">{{this.dataset.requireApproval}}</h3>
                </div>  
            </div>
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title">Approvers</h3>
                    <app-render-control-values [value]="this.dataset.approvers" [type]="'tag'" [isTagLabel]=true></app-render-control-values>
                </div>
            </div>
        </div>
        <div class="container productTable" [class.loading]="loaderActive" *ngIf="!isDraftProduct">
                    <div class="table-wrapper">
                        <table>
                                <tr>
                                    <th>API Name</th>
                                    <th>API Owner</th>
                                    <!-- <th>Security</th>
                                    <th>Action</th>-->
                                </tr>             
                            <tbody>
                                <ng-container *ngFor="let api of this.dataset.apiData; let i = index">
                                    <tr>
                                        <td><a [routerLink]="'/publisher/view-api/'+api.apimApiName" class="blue-content" (click)="onViewApiClick(api.apimApiName, api.name)">{{api.name}}</a></td>
                                        <td class="bold-content">{{api.apiOwner}}</td>
                                       <!-- <td>No OAuth</td>
                                        <td>
                                            <ng-container>
                                                <span class="action"><a  [routerLink]=""><img src="../../../assets/table/edit.svg"></a></span>
                                                <span class="action"><a  [routerLink]=""><img src="../../../assets/table/download.svg"></a></span>
                                                <span class="action"><a  [routerLink]=""><img src="../../../assets/table/bin.svg"></a></span>
                                            </ng-container>
                                        </td>-->
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>                
        </div>
    </div>
    <div class="info-section container">
        <app-information-section [information]="pageInfo" [detailsInfo]="this.detailsInfo"></app-information-section>
      </div>

    </section>
</div>