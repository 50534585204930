import {  AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime, Subject, Subscription, timer } from 'rxjs';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { ApiModel } from '../models/api-model';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';

@Component({
  selector: 'app-edit-apis',
  templateUrl: './edit-apis.component.html',
  styleUrls: ['./edit-apis.component.scss']
})
export class EditApisComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy{
  dataset: any = {};
  updateApi!: ApiModel;
  editAccess:boolean = false;
  checkError: boolean = false;
  loaderActive: boolean = false;
  showSuccess:boolean = false;
  showFailure:boolean = false;
  successFull:boolean = false;
  failure:boolean = false;
  successMsg:string='';
  failureMsg:string='';
  feedbackActive: boolean = false;
  breadCrumbApiId:string='';
  breadCrumbApiName:string='';
  informationData:any[]=[];
  showApiDetails:boolean = false;
  subscriptions: Subscription[] = [];
  tagSubject: Subject<string> = new Subject();
  tagsValue:any=[];  
  @ViewChildren('contentSection') contentSections!: ElementRef[];
  @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
  sectionHeight = 0;
  tagsDataset : any =[];  
  EditApiForm : FormGroup = new FormGroup({});
  ApiName: FormControl = new FormControl('', [Validators.required]);
  Description: FormControl = new FormControl('', [Validators.required]);
  Tags: FormControl = new FormControl('', [Validators.required, this.validateTagCharacters()]);
  ApiOwner: FormControl = new FormControl('', [Validators.required]);
  displayApiOwner: FormControl = new FormControl("",[Validators.required])
  ApiId = new FormControl('');
  AppNameAndNumber : FormControl = new FormControl('');
  userSubject: Subject<string> = new Subject();
  tagValueErrorMsg = ''; 
  pageInfo:string="An API enables a standardized way of data exchange between applications. An API specification is a clear contract defining how to interact with an application's data. An API belongs to one or more Products. Once an API becomes part of a Product, an application can subscribe to that Product to get access to that API.\n\nYou are about to edit an existing API in Azure API Management."
  apiOwnerList:{                      
    'id': string,
    "label": string,
    'value': string                
  }[] = [];
  selectedAPIOwner:string ="";


@HostListener('window:resize')
onResize() {
  this.setContentSectionHeight();
}

setContentSectionHeight() {
  const windowHeight = window.innerHeight;
  let bodyWrapperHeight: any;
  this.bodyWrapper?.forEach(section =>{
    bodyWrapperHeight = windowHeight - section.nativeElement.offsetTop;
  });
    this.contentSections?.forEach(section => {
      const offsetTop = section.nativeElement.offsetTop;
      console.log('window-height', bodyWrapperHeight, 'offset-Top', offsetTop);
      
      const sectionHeight = bodyWrapperHeight - offsetTop - 42;
      section.nativeElement.style.minHeight = `${sectionHeight}px`;
    });
}
constructor(private hipManagementService: Hip2ManagementService, 
  private cdr: ChangeDetectorRef, private route: ActivatedRoute, private router: Router, private errorHandleService: ErrorHandleService){
    let passedData:any={}; // Type is any as it will map input data with different structure from many pages i.e ds table , View Product 
    passedData=this.router.getCurrentNavigation()?.extras.state??{apiName:""};
    if(passedData.parentData != undefined){
      this.breadCrumbApiName=passedData.parentData.apiName;
      sessionStorage.setItem(this.route.snapshot.paramMap.get('apiId') ?? "",this.breadCrumbApiName);
    }else{
      this.breadCrumbApiName=sessionStorage.getItem(this.route.snapshot.paramMap.get('apiId') ?? "")??"";
    }
  }
  ngOnInit(): void {    
    this.breadCrumbApiId = this.route.snapshot.paramMap.get('apiId') ?? "";

    this.EditApiForm =new FormGroup({
      ApiName : this.ApiName,
      Description: this.Description,
      Tags: this.Tags,
      ApiId: this.ApiId,
      AppNameAndNumber: this.AppNameAndNumber,
      AppOwner: new FormControl(''),
      ApiVersion: new FormControl(''),
      ApiOwner: this.ApiOwner,
      displayApiOwner: this.displayApiOwner
    });
    this.getApiDetails(this.breadCrumbApiId);    
  }

  getApiDetails(apimApiId:string) {
    this.loaderActive = true;
    this.hipManagementService.getApimApiPublisherId(apimApiId).subscribe(
        {          
          next: (apiResponse:any) => {            
            if(apiResponse.hasAccess) {
              this.editAccess = !(apiResponse.hasReadOnlyAccess);              
              let apiData = apiResponse.apimApiDetail;
              this.breadCrumbApiName=apiData.displayName;
              this.selectedAPIOwner=apiData.apiOwner;
              this.dataset = {
                apiName: apiData.displayName,
                apimApiId: apiData.apiName,
                appName: apiData.applicationName,
                appOwner: apiData.applicationOwner,
                appNumber: apiData.cmdbId,
                businessArea: apiData.businessArea,
                costCenter:apiData.costCenter,
                unitCode:apiData.unitCode,
                product: apiData.product,
                apiVersion: apiData.version,
                apiDescription: apiData.apiDescription,                
                urlSuffix: apiData.urlSuffix            
              }
              apiData.tags.forEach(
                (element: {displayName: string; name: string;}) => {
                  let existingTag = {
                    "displayName": element.displayName,
                    "apimTagId": element.name
                  }
                  this.tagsValue.push(existingTag);
                });
              this.setFormValue(this.dataset);
              this.loaderActive = false;
              this.setAPIOwner();
            }
            else {
              this.loaderActive = false;
              this.router.navigate(['/unauthorized']);
            }               
          },
          error: (error) => {
            this.loaderActive = false; 
            this.errorHandleService.handleError(error);           
          }
        });
  }

  setFormValue(objApi:any){        
    this.EditApiForm.patchValue({
      ApiName : this.dataset.apiName,
      Description: this.dataset.apiDescription,
      Tags: this.tagsValue,
      ApiId: this.dataset.apimApiId,
      AppNameAndNumber: this.dataset.appName + " / "+ this.dataset.appNumber,
      AppOwner:this.dataset.appOwner,
      ApiVersion:this.dataset.apiVersion,
      ApiOwner:this.dataset.apiOwner,
      displayApiOwner:this.dataset.apiOwner
    })
    this.informationData = [
      {'title':'Application Name', 'value':objApi.appName },
      {'title':'Application Number', 'value':objApi.appNumber },          
      {'title':'Business Area', 'value': objApi.businessArea },
      {'title':'Unit Code', 'value': objApi.unitCode },
      {'title':'Cost Center', 'value': objApi.costCenter }
    ];             
    
    this.showApiDetails=true;    
   }
  preventSubmit(event: any){  
    event.preventDefault();
  }

  getApiTags(searchText: string):any {    
    this.hipManagementService.getApimTags(searchText).subscribe({  
      next:(response:any)=>{
        if(response.length > 0) {
          this.tagsDataBind(response);              
        }
        else {
          this.tagsDataset=[];
        }        
      },
      error:(error:any)=>{
        this.tagsDataset=[];
      }     
    });
  }

  tagsDataBind(data:any) {
    this.tagsDataset=[];
    data.forEach((element: { displayName: any; apimTagId: any; }) => {      
      this.tagsDataset.push(element);
    });
  }

  tagSearch(searchTerm: string) {
    this.tagSubject.next(searchTerm);    
  }

  validateTagCharacters() : ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => { 
      var isError:boolean=false;
      var allowedCharacter = "^[a-zA-Z0-9- ]+$";
      var allowedFormat = '^[a-zA-Z0-9]+[a-zA-Z0-9- ]*[a-zA-Z0-9]+$'; 
      if(control.value !== undefined && control.value != ""){
        try{
          let specificErrMsg='';
          control.value.forEach((element: any) => {
            if(element.displayName.length > 80){              
              specificErrMsg ='Each tag should not exceed 80 characters';
              isError=true;
            }
            else {
              if(element.displayName.search(allowedCharacter) == -1){
                specificErrMsg ='Only letters, numbers and hyphens are allowed';
                isError=true;
              } else if (element.displayName.search(allowedFormat) == -1) {
                specificErrMsg ='The first and last character must be a letter or a number';
                isError=true;
              }
            }            
          });            
          if(isError){
            this.checkError=true;    
            this.tagValueErrorMsg=specificErrMsg;      
            return {"isInvalidTagCharacter": true };            
          }
          else {
            this.checkError=false;
            this.tagValueErrorMsg='';
            return null;
          }          
        }
        catch(exception){  
          this.checkError=false;
          this.tagValueErrorMsg='';        
          return null;
        }          
      }
      return null;
    };         
   }

  getControl(control: string): FormControl {
    return this.EditApiForm.get(control) as FormControl;
  }

  onSubmit() { 
    if(this.EditApiForm.valid){
      this.upsertApi();
    }else {
      this.checkError = true;   
    }
  }

  upsertApi(){    
  var tagsList:any[]=[];
  this.EditApiForm.value.Tags.forEach((element: any) => {
  let tagId:string=element.apimTagId;
  if(tagId.includes(" ")){
    tagId=tagId.replaceAll(" ","-");
    tagsList.push({"displayName":element.displayName, "Name": tagId });
  }else{
    tagsList.push({"displayName":element.displayName, "Name": element.apimTagId });
  }
  });
  this.updateApi = {    
    apimApiId:  this.EditApiForm.value.ApiId,
    displayName: this.EditApiForm.value.ApiName,
    description: this.EditApiForm.value.Description,
    businessAreaCode: this.dataset.businessArea,
    cmdbId:this.dataset.appNumber,
    format:"swagger-json",
    serviceUrl:"",
    tags:tagsList,
    urlSuffix:this.dataset.urlSuffix,
    apiOwner:this.selectedAPIOwner
  }
  this.loaderActive = true;
  this.hipManagementService.editApi(this.updateApi).subscribe({
    next:(response:any) => {
      this.loaderActive = false;
      this.successMsg= this.breadCrumbApiId + ' updated successfully';
        this.showSuccess=true;             
        this.feedbackActive =true; 
        this.successFull = true; 
        this.failure = false; 
        timer(5000).subscribe(x => this.showSuccess = false);       
    },
    error:(error:any) => {        
      this.loaderActive = false;
      let errMsg='';
      if(error.error.message){
        errMsg=error.error.message;
      }
      else if(error.error){
        errMsg=error.error;
      }      
      this.failureMsg = this.breadCrumbApiId + ' update failed with exception: ' + errMsg;
      this.showFailure = true;  
      this.feedbackActive =true;
      this.successFull = false;
      this.failure = true;  
      timer(5000).subscribe(x => this.showFailure = false);             
    }
  });  
  }
  onClick(pageName:string):void{
    this.router.navigate([`${pageName}`]);
  }

  cancelAllData(){
    this.EditApiForm.reset();
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.setContentSectionHeight();    
  }

  getAdUsers(searchText: string) {    
    this.hipManagementService.getListOfADUsersOwners(searchText).subscribe({  
      next:(response)=>{
        if(response.length > 0) {
          this.apiOwnerDataBind(response);              
        }
        else {          
          this.apiOwnerList =[];
        }        
      },
      error:(error:any)=>{
        console.log(error);      
        this.apiOwnerList =[];
        this.EditApiForm.patchValue({
          ApiOwner:""
        })       
      }     
    });
  }

  apiOwnerDataBind(data:{ email: string; name: string; }[]) {
    this.apiOwnerList=[];
    data.forEach((element: { email: string; name: string; }) => {      
      this.apiOwnerList.push({                      
        'id': element.email,
        "label": element.name,
        'value': element.name + ' ['+element.email+']'                
      });  
    });
  }

  ngAfterContentInit(): void {  
    this.subscriptions.push(this.tagSubject.pipe(debounceTime(1500)).subscribe(value =>{      
      if (value.length > 2) {
        this.getApiTags(value);          
      }
      else {
        this.tagsDataset = [];
      }      
    }));   
    
    this.subscriptions.push(this.userSubject.pipe(debounceTime(1500)).subscribe(value =>{      
      if (value.length > 2) {
        this.getAdUsers(value);          
      }
      else {
        this.apiOwnerList = [];
      }      
    })); 
    
    this.setContentSectionHeight();
  }

  userSearch(searchValue: string){     
    this.userSubject.next(searchValue);      
   }

   apiOwnerSelected(event:any){
    this.selectedAPIOwner=event.id;
   }

  setAPIOwner(){
    this.loaderActive=true;
    this.hipManagementService.getListOfADUsersOwners(this.selectedAPIOwner).subscribe({  
      next:(response)=>{
        if(response.length > 0) {
          this.EditApiForm.patchValue({
            ApiOwner:response[0].email,
            displayApiOwner:response[0].name
          })
          this.apiOwnerDataBind(response);              
       }  
       this.loaderActive=false;      
      },
      error:(error:any)=>{
        console.log(error);
        this.loaderActive=false;            
      }     
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}